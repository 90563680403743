import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import "./SnakeEndSceneOne.scss";
import getClasses from "utils/getClasses";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * SnakeEndSceneOne Component
 *
 * This component represents a first chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} page - Indicates number of the scene inside component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const snakeEndSceneOneData: SceneDataT = [
  {
    title: "Ziggy",
    text: ["Finalmente! Grazie per averci liberato!"],
    audio: {
      ziggy: "snake/ziggy",
    },
    images: {},
  },
];

export const SnakeEndSceneOne: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const contents: SceneDataT = snakeEndSceneOneData;

  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[contents[0].audio!.ziggy]}
        start={Boolean(page)}
        setFinished={() => setIsFinished(true)}
        endDelay={1000}
      />
    ),
    [contents, page, setIsFinished]
  );
  return (
    <div
      className={`snake-end-container fade-in-image ${getClasses({
        "fade-out-image": out && page === 3,
      })}`}
    >
      {renderSpeech}
      <div className="content">
        <div className={`balloon-container fade-in-image`}>
          <SimpleCard title={contents[0].title} text={contents[0].text} />
        </div>
      </div>
    </div>
  );
};
