import { SceneEnum } from "models/EnumScene";
import { buttonLabels, ComponentsData } from "./declaration";
import { EnumPath, EnumSceneFlow } from "models/EnumPath";
import { SnakeFwdIntroStart } from "pages/private/interludes/SnakeGame/Forward/ForwardStarted/SnakeFwdIntroStart";
import { GameAlbum } from "pages/private/interludes/genericsInterludes/Album/GameAlbum/GameAlbum";
import { SnakeFwdIntroSceneOne } from "pages/private/interludes/SnakeGame/Forward/ForwardOne/SnakeFwdIntro";
import { SnakeEndSceneOne } from "pages/private/interludes/SnakeGame/End/EndOne/SnakeEndSceneOne";
import { SnakeEndSceneTwo } from "pages/private/interludes/SnakeGame/End/EndTwo/SnakeEndSceneTwo";
import { SnakeSpinoffEndScene } from "pages/private/interludes/SnakeGame/End/EndSpinoff/SnakeSpinoffEndScene";
const { skip, next, play } = buttonLabels;

const snakeScenePath = `/${EnumPath.GAMES}/${EnumPath.SNAKE_GAME}/${EnumPath.SCENE}`;

const skipToGameButtonNormal = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.SNAKE_GAME}/${EnumPath.PLAY_REVERSE}`,
};

const spinoffContent = (currentSpinoff: SceneEnum) => ({
    one: {
        component: SnakeFwdIntroSceneOne,
        multipage: 2,
        actions: {
            close: true,
            skip: {
                ...skipToGameButtonNormal,
                label: [skipToGameButtonNormal.label, ""],
            },
            back: {
                label: "",
                path: "",
            },
            next: {
                label: [next, play],
                path: `/${EnumPath.GAMES}/${EnumPath.SNAKE_GAME}/${EnumPath.PLAY_REVERSE}`,
            },
        },
    },
    two: {
        component: SnakeSpinoffEndScene,
        actions: {
            close: true,
            skip: {
                label: skip,
                path: `${snakeScenePath}/${EnumSceneFlow.SPINOFF}/${currentSpinoff}-three`,
            },
            next: {
                label: next,
                path: `${snakeScenePath}/${EnumSceneFlow.SPINOFF}/${currentSpinoff}-three`,
            },
        },
    },
    three: {
        component: SnakeEndSceneTwo,
        actions: {
            next: {
                label: "",
                path: `${snakeScenePath}/${EnumSceneFlow.ALBUM}/${SceneEnum.FORWARD_ONE}`,
            },
        },
    },
});

export const snakeContent: ComponentsData["snake"] = {
    forward: {
        [SceneEnum.FORWARD_STARTED]: {
            component: SnakeFwdIntroStart,
            actions: {
                close: true,
                next: {
                    label: "",
                    path: `${snakeScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                },
            },
        },
        [SceneEnum.FORWARD_ONE]: {
            component: SnakeFwdIntroSceneOne,
            multipage: 3,
            actions: {
                close: true,
                skip: skipToGameButtonNormal,
                back: {
                    label: "",
                    path: `${snakeScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_STARTED}`,
                },
                next: {
                    label: next,
                    path: `/${EnumPath.GAMES}/${EnumPath.SNAKE_GAME}/${EnumPath.PLAY_REVERSE}`,
                },
            },
        },
    },
    end: {
        [SceneEnum.END_ONE]: {
            component: SnakeEndSceneOne,
            actions: {
                close: true,
                skip: {
                    label: skip,
                    path: `${snakeScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                },
                next: {
                    label: next,
                    path: `${snakeScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                },
            },
        },
        [SceneEnum.END_TWO]: {
            component: SnakeEndSceneTwo,
            actions: {
                next: {
                    label: "",
                    path: `${snakeScenePath}/${EnumSceneFlow.ALBUM}/${SceneEnum.FORWARD_ONE}`,
                },
            },
        },
    },
    spinoff: {
        [SceneEnum.SPINOFF_FIRST_ONE]: spinoffContent(SceneEnum.SPINOFF_FIRST).one,
        [SceneEnum.SPINOFF_FIRST_TWO]: spinoffContent(SceneEnum.SPINOFF_FIRST).two,
        [SceneEnum.SPINOFF_FIRST_THREE]: spinoffContent(SceneEnum.SPINOFF_FIRST).three,

        [SceneEnum.SPINOFF_SECOND_ONE]: spinoffContent(SceneEnum.SPINOFF_SECOND).one,
        [SceneEnum.SPINOFF_SECOND_TWO]: spinoffContent(SceneEnum.SPINOFF_SECOND).two,
        [SceneEnum.SPINOFF_SECOND_THREE]: spinoffContent(SceneEnum.SPINOFF_SECOND).three,

        [SceneEnum.SPINOFF_DEFAULT_ONE]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).one,
        [SceneEnum.SPINOFF_DEFAULT_TWO]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).two,
        [SceneEnum.SPINOFF_DEFAULT_THREE]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).three,
    },
    album: {
        [SceneEnum.FORWARD_ONE]: {
            component: GameAlbum,
            multipage: 4,
            actions: {
                close: true,
                next: {
                    label: next,
                    path: `/dashboard/game-chapters`,
                },
            },
        },
    },
};
