export enum EnumSnakeGameSquare {
    AMBULANCE = "ambulance",
    APPLAUSE = "applause",
    CAT = "cat",
    CHILD_LAUGHS = "child-laughs",
    CHILD_CRYING = "child-crying",
    CICADA = "cicada",
    CLACSON = "clacson",
    COW = "cow",
    DOG = "dog",
    DOLLS = "dolls",
    DRILL = "drill",
    DONKEY = "donkey",
    DROP = "drop",
    DRUM = "drum",
    DUCK = "duck",
    ELEPHANT = "elephant",
    FLUTE = "flute",
    FLY = "fly",
    FROG = "frog",
    GUITAR = "guitar",
    HAMMER = "hammer",
    HAND_SAW = "hand-saw",
    HELICOPTER = "helicopter",
    HORSE = "horse",
    HOWL = "howl",
    LION = "lion",
    LITTLE_BIRD = "little-bird",
    MARACAS = "maracas",
    MONKEY = "monkey",
    PHONE = "phone",
    PIANO = "piano",
    PIG = "pig",
    PLANE = "plane",
    ROOSTER = "rooster",
    SHEEP = "sheep",
    SINGER = "singer",
    SNEEZE = "sneeze",
    TOILET_FLUSH = "toilet-flush",
    TRAIN_WHISTLE = "train-whistle",
    TRIANGLE = "triangle",
    TRUMPTOT = "trumpot",
    VACUUM = "vacuum",
    VIOLIN = "violin",
    WHISTLE = "whistle",
    WOLF = "wolf",
}
