import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import React, { useMemo } from "react";
import { FC } from "react";
import "./FinalMessageFwdThree.scss";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { ActionCard } from "components/ActionCard/ActionCard";
import { useNavigate } from "react-router-dom";
import detectiveFeedBack from "assets/img/common/Detective/feedback.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * FinalMessageFwdTwo Component
 *
 * This component represents the fifth and final part of the end of a chapter in the story.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The rendered component.
 */

const content: SceneDataT = [
  {
    title: "1. Diventa detective della natura",
    text: [
      "Conosci gli animali che vivono in boschi e foreste. Ognuno di loro ha un ruolo nell'equilibrio naturale. Avvisa sempre un adulto se dovessi trovarne uno in difficoltà.",
    ],
    images: {},
    audio: { felix: "message/felix-tip-1" },
  },
  {
    title: "2. Rispetta sempre l’ambiente",
    text: [
      "Non lasciare mai rifiuti in giro, soprattutto quando visiti riserve naturali, boschi e foreste. Potrebbero ferire gli animali e danneggiare l'ambiente.",
    ],
    images: {},
    audio: { felix: "message/felix-tip-2" },
  },
  {
    title: "3. Diventa amico degli animali, \namandoli e rispettandoli.",
    text: [
      "Anche se sono bellissimi e ci piacerebbe averli vicini, gli animali selvatici sono felici solo nel loro ambiente naturale. Catturarli e portarli altrove è egoista e ingiusto.",
    ],
    images: {},
    //TODO: da sostituire con l'audio completo
    audio: { felix: "message/felix-tip-3" },
  },
  {
    title: "Complimenti! \nContinua a giocare.",
    text: [""],
    images: {},
    audio: { felix: "album/detective-felix-extra" },
  },
];

const buttonLabel = [
  {
    label: "Gioca",
    path: "/dashboard/game-chapters",
  },
  {
    label: "Vai all'album",
    path: "/games/album",
  },
];

export const FinalMessageFwdThree: FC<Partial<ChapterSceneFC>> = React.memo(
  ({ page = 1, action }) => {
    const navigate = useNavigate();
    const contents = content;
    const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

    const renderSpeech = useMemo(
      () => (
        <SpeechAudio
          notVisible
          audioFiles={[contents[page - 1].audio!.felix]}
          start={Boolean(page)}
          setFinished={() => {
            if (page < 4) {
              setIsFinished(true);
            }
          }}
        />
      ),

      [contents, page, setIsFinished]
    );

    return (
      <div className={`final-message-tips background-${page} fade-in-image`}>
        {page === 4 ?
          <div className="card-message">
            <ActionCard
              title={contents[page - 1].title}
              buttonText={buttonLabel[0].label}
              action={() => navigate(buttonLabel[0].path || "")}
              secondaryButton={{
                label: buttonLabel[1].label,
                action: () => navigate(buttonLabel[1].path || ""),
              }}
            />
            <img className="img-felix-1" src={detectiveFeedBack} alt="Felix" />
          </div>
        : <div className="balloon-container-message">
            <SimpleCard title={contents[page - 1].title} text={contents[page - 1].text} />
          </div>
        }
        {renderSpeech}
      </div>
    );
  }
);
