import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useGamePath } from "hook/useGamePath";
import { ChapterSceneFC, IMultiOptionScene, SceneDataT } from "models/ChapterSceneFC";
import { EnumGame } from "models/EnumPath";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import grassBottom from "assets/img/snake/grass_1.webp";
import spike from "assets/img/snake/spike.webp";
import filo from "assets/img/snake/filo.webp";
import akira from "assets/img/snake/akira.webp";
import "./SnakeSpinoffEndScene.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";

const createSceneData = (title: string, audioFile: string, image: string) => ({
  title,
  text: ["Finalmente! Grazie per averci liberato!"],
  audio: {
    snake: audioFile,
  },
  images: {
    snakes: image,
    greenGrass: grassBottom,
  },
});

const SnakeSpinoffEndSceneData: IMultiOptionScene = {
  spinoffFirst: [createSceneData("Spike", "snake/spike", spike)],
  spinoffSecond: [createSceneData("Filo", "snake/filo", filo)],
  spinoffDefault: [createSceneData("Akira", "snake/akira", akira)],
};

type dataKeyT = keyof typeof SnakeSpinoffEndSceneData;

export const SnakeSpinoffEndScene: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { currentSceneId } = useGamePath(EnumGame.SNAKE_GAME);
  const dataKey = currentSceneId?.split("-")[0] as dataKeyT;
  const contents: SceneDataT = SnakeSpinoffEndSceneData[dataKey];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const getBackgroundImage = (dataKey: dataKeyT) => {
    switch (dataKey) {
      case "spinoffFirst":
        return "snake-spinoff-first-end";
      case "spinoffSecond":
        return "snake-spinoff-second-end";
      case "spinoffDefault":
        return "snake-spinoff-default-end";
      default:
        return "";
    }
  };

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[contents[page - 1].audio!.snake]}
        start={Boolean(page)}
        startDelay={page === 1 ? 1000 : 0}
        endDelay={1000}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [contents, page, setIsFinished]
  );

  return (
    <div
      className={`${getBackgroundImage(dataKey)} fade-in-image ${getClasses({
        "fade-out-image": out && page === 3,
      })}`}
    >
      <div className="content">
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { snakes, greenGrass },
          } = contents[pageDiv];
          return (
            <div key={`scene-page-${pageDiv + 1}`} className="page-container">
              {snakes && (
                <img className="free-snakes fade-in-image" src={snakes} alt="snakes" />
              )}
              <div className={`balloon-container fade-in-image`}>
                <SimpleCard title={title} text={text} />
              </div>
              {renderSpeech}
              {greenGrass && <img className="grass-bottom" src={greenGrass} alt="grass" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
