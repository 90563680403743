import { ActionCard } from "components/ActionCard/ActionCard";
import { RoundButton } from "components/RoundButton/RoundButton";
import { DemoGameFC } from "models/DemoGameFC";
import { EnumPath } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { FC } from "react";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import plant from "assets/img/snake/elements/plant-top.svg";

import detectiveIntroDemo from "assets/img/common/Detective/proof.svg";
import detectiveOk from "assets/img/common/Detective/ok.svg";
import detectiveHappy from "assets/img/common/Detective/let's-play-spinoff.svg";
import "./DemoSnakeGame.scss";
import { useEffectAudio } from "hook/useEffectAudio";
import { TypeGameEnum } from "models/EnumModeGame";

export const DemoSnakeGame: FC<Partial<DemoGameFC>> = ({ action, title, startTutorial }) => {
  const { currentScene, type } = useSelector(modeGameSelector);
  const { volumeVoice } = useEffectAudio();

  return (
    <div data-cy="demo-toucan-game" className="container-demo container-demo-snake">
      <div className="button-top-close">
        <RoundButton type="close" linkButton={`/${EnumPath.DASHBOARD}`} />
      </div>
      <img className="plant" src={plant} alt="plant" />
      {!currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
        <img
          className="detective"
          src={type === TypeGameEnum.START_MODAL_GAME ? detectiveOk : detectiveIntroDemo}
          alt="detective"
          data-cy="demo-toucan-game-figure"
        />
      : <img className="detective" src={detectiveHappy} alt="detective happy" />}

      <div className="card-center">
        <ActionCard
          title={title}
          buttonText="Ok"
          action={action}
          repeatTutorial={startTutorial}
          buttonDisabled={!volumeVoice}
          showTutorialButton={currentScene.includes(SceneEnum.SPINOFF_SCENE)}
        />
      </div>
    </div>
  );
};
