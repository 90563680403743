import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useEffectAudio } from "hook/useEffectAudio";
import waitingFelix from "assets/img/common/Detective/waiting-felix.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setIsFirstGame } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import "./SlothRevIntroStart.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * SlothRevIntroStart Component
 *
 * This component represents a second chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const slothRevIntroStartData: SceneDataT = [
  {
    title: "Detective Felix",
    text: [
      "Grande! Dobbiamo giocare solo un'altra volta e poi potremo restituire a Brady il suo albero!",
    ],
    audio: {
      felix: "sloth/detective-felix-08",
    },
    images: {
      felix: waitingFelix,
    },
  },
];

export const SlothRevIntroStart: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const dispatch = useDispatch();
  //TODO move into SlothGame navigateAftterGame handler
  useEffect(() => {
    dispatch(setIsFirstGame(false));
  }, [dispatch]);

  const contents = slothRevIntroStartData;
  const {
    title,
    text,
    images: { felix },
  } = contents[page - 1];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const { volumeVoice } = useEffectAudio();
  const renderSpeech = useMemo(
    () =>
      Boolean(volumeVoice) && (
        <SpeechAudio
          notVisible
          start={true}
          audioFiles={[`${contents[0].audio?.felix}`]}
          setFinished={() => setIsFinished(true)}
          endDelay={1000}
          startDelay={1000}
        />
      ),
    [setIsFinished, contents, volumeVoice]
  );

  return (
    <div
      className={`sloth-chapter-second-one-container fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="background background-overlay"></div>
      <div className="content">
        <div className="alone"></div>
        <div className="page-container">
          <div className="balloon-container">
            <SimpleCard title={title} text={text} />
            {renderSpeech}
          </div>
          <img className="felix-figure" src={felix} alt="felix" />
        </div>
      </div>
    </div>
  );
};
