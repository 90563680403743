import happyMommy from "assets/img/toucan/elements/happy-mum-spinoff.svg";
import happyBrio from "assets/img/toucan/elements/small-toucan-fly.svg";
import happyLittleToucan from "assets/img/toucan/elements/small-toucan-happy.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, IMultiOptionScene, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import "./ToucanSpinoffSceneThree.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useGamePath } from "hook/useGamePath";
import { EnumGame } from "models/EnumPath";

/**
 * ToucanSpinoffSceneThree Component
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const toucanSpinoffSceneThreeData: IMultiOptionScene = {
  spinoffFirst: [
    {
      title: "Brio",
      text: ["Finalmente libero! Evviva!"],
      images: {
        toucan: happyMommy,
        brio: happyBrio,
        littleToucan: happyLittleToucan,
      },
      audio: {
        toucan: "toucan/brio-spinoff-first-3",
      },
    },
  ],
  spinoffSecond: [
    {
      title: "Dodo",
      text: ["Grazie per avermi liberato. Finalmente posso tornare a svolazzare!"],
      images: {
        toucan: happyMommy,
        brio: happyBrio,
        littleToucan: happyLittleToucan,
      },
      audio: {
        toucan: "toucan/dodo-spinoff-second-3",
      },
    },
  ],
  spinoffDefault: [
    {
      title: "Piccolo Tucano",
      text: ["Grazie per avermi liberato. Finalmente posso tornare a svolazzare!"],
      images: {
        toucan: happyMommy,
        brio: happyBrio,
        littleToucan: happyLittleToucan,
      },
      audio: {
        toucan: "toucan/piccoloTucano-spinoff-default-3",
      },
    },
  ],
};

type dataKeyT = keyof typeof toucanSpinoffSceneThreeData;

export const ToucanSpinoffSceneThree: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { currentSceneId } = useGamePath(EnumGame.TOUCAN_GAME);
  const dataKey = currentSceneId?.split("-")[0] as dataKeyT;
  const contents: SceneDataT = toucanSpinoffSceneThreeData[dataKey];
  const [
    {
      title,
      text,
      images: { toucan, brio, littleToucan },
    },
  ] = contents;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[`${contents[0].audio?.toucan}`]}
        start={true}
        endDelay={1000}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [contents, setIsFinished]
  );

  return (
    <div
      className={`toucan-spinoff-scene-three fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        <div className={`page-container`}>
          <img className="toucan-figure mommy" src={toucan} alt="Happy Mommy on a tree" />
          <div className="balloon-container">
            <SimpleCard title={title} text={text} />
          </div>
          {renderSpeech}
          <img className="toucan-figure little" src={littleToucan} alt="Happy little toucan" />
          <img className="toucan-figure brio" src={brio} alt="Happy flying Brio" />
        </div>
      </div>
    </div>
  );
};
