import { EnumSnakeGameSquare } from "data/snakeGameStimuli";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useEffectAudio } from "./useEffectAudio";
import { useDispatch, useSelector } from "react-redux";
import { setScores } from "store/slices/currentSessionSlice";
import { modeGameSelector, setModeGame, setSnakeStep } from "store/slices/modeGameSlice";
import { TypeGameEnum } from "models/EnumModeGame";

interface TSnakePointCycle {
  rightCouples: number;
  wrongCouples: number;
  audioSequence: boolean;
  words: number;
}

type PointsAction =
  | { type: "RESET_POINTS" }
  | { type: "RESET_CYCLE" }
  | { type: "UPDATE_SQUARE_SCORE"; payload: { isCorrect: boolean } }
  | { type: "UPDATE_NOISE_SCORE"; payload: { audioSequence: boolean; words: number } }
  | { type: "ADD_CYCLE_TO_POINTS" };

interface PointsState {
  currentPointCycle: TSnakePointCycle;
  points: TSnakePointCycle[];
}

const initialState: PointsState = {
  currentPointCycle: {
    rightCouples: 0,
    wrongCouples: 0,
    audioSequence: false,
    words: 0,
  },
  points: [],
};

const pointsReducer = (state: PointsState, action: PointsAction): PointsState => {
  switch (action.type) {
    case "RESET_POINTS":
      return {
        ...state,
        points: [],
      };

    case "RESET_CYCLE":
      return {
        ...state,
        currentPointCycle: initialState.currentPointCycle,
      };

    case "UPDATE_SQUARE_SCORE":
      const { isCorrect } = action.payload;
      return {
        ...state,
        currentPointCycle: {
          ...state.currentPointCycle,
          rightCouples:
            isCorrect ?
              state.currentPointCycle.rightCouples + 1
            : state.currentPointCycle.rightCouples,
          wrongCouples:
            isCorrect ?
              state.currentPointCycle.wrongCouples
            : state.currentPointCycle.wrongCouples + 1,
        },
      };

    case "UPDATE_NOISE_SCORE":
      const { audioSequence, words } = action.payload;
      return {
        ...state,
        currentPointCycle: {
          ...state.currentPointCycle,
          audioSequence,
          words,
        },
      };

    case "ADD_CYCLE_TO_POINTS":
      return {
        points: [...state.points, state.currentPointCycle],
        currentPointCycle: initialState.currentPointCycle,
      };

    default:
      return state;
  }
};

export const useSnakeGame = () => {
  const modeGame = useSelector(modeGameSelector);
  const [state, dispatch] = useReducer(pointsReducer, initialState);
  const { points, currentPointCycle } = state;

  const { activePlayWin } = useEffectAudio();
  const dispatchRedux = useDispatch();
  // #region ::: step 1 states
  const generateRandomSquareWidths = () => {
    const tinyWidth = Math.floor(Math.random() * 50) + 150;
    const largerWidth = tinyWidth + 20 + Math.floor(Math.random() * 50);
    return Math.random() > 0.5 ? [tinyWidth, largerWidth] : [largerWidth, tinyWidth];
  };
  const [squareSize, setSquareSize] = useState<Array<number>>(generateRandomSquareWidths());
  // #endregion

  // #region ::: step 2 states
  const [noiseSequence, setNoiseSequence] = useState<Array<string>>([]);
  const [noiseResponse, setNoiseResponse] = useState<Array<string>>([]);
  const [noisecards, setNoiseCards] = useState<Array<string>>([]);
  const [noiseNumber, setNoiseNumber] = useState<number>(2);

  // #endregion

  // #region ::: general states
  const [currentStep, setCurrentStep] = useState<1 | 2 | null>(null);
  const [interactionWarning, setInteractionWarning] = useState(false);
  const [isErrorWarning, setIsErrorWarning] = useState(false);
  const warningTimerRef = useRef<NodeJS.Timeout | null>(null);
  // #endregion

  // #region ::: Booleans
  const isResponseReached =
    noiseResponse.length !== 0 &&
    noiseSequence.length !== 0 &&
    noiseResponse.length === noiseSequence.length;

  const isTutorial =
    modeGame.type === TypeGameEnum.PLAYING_TUTORIAL ||
    modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL ||
    modeGame.type === TypeGameEnum.TRAINING_TUTORIAL;
  const isPlayGame = modeGame.type === TypeGameEnum.PLAY_GAME;
  const isModalPlayGame = modeGame.type === TypeGameEnum.START_MODAL_GAME;
  // #endregion

  // #region ::: step 1 functions
  const checkLargerSquareBetween = (SelectedIndex: 0 | 1) =>
    squareSize[SelectedIndex] > squareSize[SelectedIndex === 0 ? 1 : 0];

  const updateSquareCouplesScore = (isCorrect: boolean) => {
    setInteractionWarning(false);
    interactionWarningTimeOut();
    dispatch({ type: "UPDATE_SQUARE_SCORE", payload: { isCorrect } });
  };

  const onSquareCouplesResponse = (SelectedIndex: 0 | 1) => {
    const isCorrect = checkLargerSquareBetween(SelectedIndex);

    if (!isCorrect && isTutorial) {
      errorWarning();
      return false;
    } else {
      updateSquareCouplesScore(isCorrect);
      setSquareSize(generateRandomSquareWidths());
      return true;
    }
  };

  const goToStep2 = () => {
    setCurrentStep(2);
    dispatchRedux(setSnakeStep(2));
  };
  // #endregion

  // #region ::: step 2 functions

  const goToStep1 = () => {
    setCurrentStep(1);
    dispatchRedux(setSnakeStep(1));
  };

  const getRandomNoise = (array: string[], count: number): string[] => {
    const shuffled = [...array].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, count);
  };

  //incremento/decremento dei contatori delle risposte corrette/sbagliate
  const updateSequenceCounts = useCallback(() => {
    if (points.length % 2 !== 0) return noiseNumber;
    const lastTwoResponse = points.slice(-2);

    const newNoiseNumber = lastTwoResponse.reduce(
      (acc, point) => (point.audioSequence ? acc + 0.5 : acc - 0.5),
      noiseNumber
    );

    const finalNoiseNumber = Math.max(Math.ceil(newNoiseNumber), 2);

    setNoiseNumber(finalNoiseNumber);

    return finalNoiseNumber;
  }, [noiseNumber, points]);

  const buildNoiseRequest = useCallback(() => {
    const currentnoiseNumber = updateSequenceCounts();
    const snakeGameStimuli = Object.values(EnumSnakeGameSquare);

    const noiseRequest = getRandomNoise(snakeGameStimuli, currentnoiseNumber);
    const noiseRest = snakeGameStimuli.filter((noise: string) => !noiseRequest.includes(noise));

    dispatch({ type: "RESET_CYCLE" });
    setNoiseSequence(noiseRequest);

    const noiseOptionsNum = 12 - noiseRequest.length;
    const uncorrectNoise = getRandomNoise(noiseRest, noiseOptionsNum);
    const shuffledNoiseCards = [...noiseRequest, ...uncorrectNoise].sort(
      () => Math.random() - 0.5
    );

    setNoiseCards(shuffledNoiseCards);
  }, [updateSequenceCounts]);

  const addNoiseToNoiseResponse = (noise: string) =>
    noiseResponse.length < noiseSequence.length ?
      setNoiseResponse((prev) => [...prev, noise])
    : null;

  const updateNoiseSequence = useCallback(() => {
    const noiseResponseCheck = noiseSequence.every(
      (noise, index) => noise === noiseResponse[index]
    );

    if (noiseResponseCheck && isPlayGame) activePlayWin();

    if (isTutorial) {
      dispatchRedux(
        setModeGame({
          mode: modeGame.mode,
          type: TypeGameEnum.START_MODAL_GAME,
        })
      );
      return;
    }

    interactionWarningTimeOut();
    dispatch({
      type: "UPDATE_NOISE_SCORE",
      payload: { audioSequence: noiseResponseCheck, words: noiseSequence.length },
    });

    dispatch({ type: "ADD_CYCLE_TO_POINTS" });
    setNoiseResponse([]);
    setNoiseSequence([]);
    setNoiseCards([]);
  }, [
    activePlayWin,
    dispatchRedux,
    isTutorial,
    modeGame.mode,
    noiseResponse,
    noiseSequence,
    isPlayGame,
  ]);
  // #endregion

  // #region ::: general functions
  const interactionWarningTimeOut = () => {
    setInteractionWarning(false);
    if (warningTimerRef.current) {
      clearTimeout(warningTimerRef.current);
    }

    warningTimerRef.current = setTimeout(() => {
      setInteractionWarning(true);
    }, 5000);
  };

  const errorWarning = () => {
    if (!isTutorial) return;
    interactionWarningTimeOut();
    setIsErrorWarning(true);
    setTimeout(() => {
      setIsErrorWarning(false);
    }, 2000);
  };
  // #endregion

  useEffect(() => {
    if (currentStep === 2 || currentStep === 1) {
      interactionWarningTimeOut();
    }
  }, [currentStep, noiseSequence]);

  useEffect(() => {
    if (noiseSequence.length === 0) {
      //TODO: capire come non far venire troppo attaccati l'audio playWin e la sequenza dei noise
      // setTimeout(() => {
      buildNoiseRequest();
      goToStep1();
      // }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildNoiseRequest, noiseSequence.length]);

  useEffect(() => {
    setInteractionWarning(false);
    if (isResponseReached) updateNoiseSequence();
  }, [isResponseReached, updateNoiseSequence]);

  useEffect(() => {
    dispatchRedux(setScores({ scores: points }));
  }, [dispatchRedux, points]);

  //reset tutorial points
  useEffect(() => {
    if (isModalPlayGame) {
      dispatch({ type: "RESET_POINTS" });
    }
  }, [modeGame.type, isModalPlayGame]);

  return {
    squareSize,
    interactionWarning,
    isErrorWarning,
    checkLargerSquareBetween,
    onSquareCouplesResponse,
    noisecards,
    noiseSequence,
    noiseResponse,
    currentStep,
    addNoiseToNoiseResponse,
    goToStep2,
    goToStep1,
    interactionWarningTimeOut,
    setNoiseResponse,
    points: points,
    currentPointCycle: currentPointCycle,
  };
};
