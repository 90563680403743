// Define an interface for component data
export interface ButtonI {
    label: string | string[];
    path: string;
}
export interface ComponentActions {
    close?: boolean;
    skip?: ButtonI;
    back?: ButtonI;
    next?: ButtonI;
}
export type ComponentActionsKeys = Exclude<keyof ComponentActions, "close">;
export interface ComponentData {
    component: React.ComponentType<any>;
    multipage?: number;
    actions: ComponentActions;
}
export interface SceneMap {
    [key: string]: ComponentData;
}
// Define an interface for game data
export interface GameData {
    forward: SceneMap;
    reverse?: SceneMap;
    end: SceneMap;
    spinoff: SceneMap;
    album: SceneMap;
}
// Define an interface to store components data
export interface ComponentsData {
    intro: Partial<GameData>;
    parrot: GameData;
    toucan: GameData;
    sloth: GameData;
    snake: GameData;
    "final-message": Partial<GameData>;
}

// button labels
export const buttonLabels = {
    skip: "salta l'intro",
    next: "avanti",
    play: "giochiamo",
};
