import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EnumCurrentSessionAnswer, EnumCurrentSessionMode } from "models/EnumCurrentSession";
import { SceneEnum } from "models/EnumScene";
import { CurrentSession, GamingScore, SlothScore } from "models/Session";
import { RootState } from "../store";

const initialState: CurrentSession = {
    id: "",
    userId: "",
    gameName: "",
    gameInfo: {
        isReverseGame: false,
        completed: false,
        nBack: 1,
        scores: [],
        score: {
            forwardScore: [{ squares: 2, rightAnswers: 0, wrongAnswers: 0 }],
            reverseScore: [{ squares: 2, rightAnswers: 0, wrongAnswers: 0 }],
            firstPart: { rightCouples: 0, wrongCouples: 0, skippedCouples: 0 },
            secondPart: { rightCouples: 0, wrongCouples: 0, skippedCouples: 0 },
        },
    },
    spinOffScene: SceneEnum.EMPTY,
};

export const currentSessionSlice = createSlice({
    name: "currentSession",
    initialState,
    reducers: {
        setCurrentSession: (state, action: PayloadAction<Partial<CurrentSession>>) => {
            if (action.payload.gameInfo) {
                return {
                    ...state,
                    ...action.payload,
                    spinoffScene: action.payload.spinOffScene,
                    gameInfo: {
                        ...action.payload.gameInfo,
                    },
                };
            }
        },
        setSlothScore: (
            state,
            action: PayloadAction<{
                gamePart: "firstPart" | "secondPart";
                score: SlothScore;
            }>
        ) => {
            return {
                ...state,
                gameInfo: {
                    ...state.gameInfo,
                    score: {
                        ...state.gameInfo.score,
                        [`${action.payload.gamePart}`]: {
                            ...action.payload.score,
                        },
                    },
                },
            };
        },
        setScore: (
            state,
            action: PayloadAction<{
                answer: EnumCurrentSessionAnswer;
                mode: EnumCurrentSessionMode;
            }>
        ) => {
            const { answer, mode } = action.payload;
            const scoreState = state.gameInfo.score;

            const updateScore = (
                scores: GamingScore[],
                squares: number,
                answer: EnumCurrentSessionAnswer
            ): GamingScore[] => {
                const index = scores?.findIndex(
                    ({ squares: _squares }) => _squares === squares
                );
                const updatedScore = [...scores];
                if (index !== -1) {
                    const score = state.gameInfo.score[`${mode}Score`][index];
                    updatedScore[index] = {
                        squares,
                        rightAnswers:
                            answer === EnumCurrentSessionAnswer.ANSWER_RIGHT ?
                                score.rightAnswers + 1
                            :   score.rightAnswers,
                        wrongAnswers:
                            answer === EnumCurrentSessionAnswer.ANSWER_WRONG ?
                                score.wrongAnswers + 1
                            :   score.wrongAnswers,
                    };
                } else {
                    updatedScore.push({
                        squares,
                        rightAnswers: answer === EnumCurrentSessionAnswer.ANSWER_RIGHT ? 1 : 0,
                        wrongAnswers: answer === EnumCurrentSessionAnswer.ANSWER_WRONG ? 1 : 0,
                    });
                }
                return [...updatedScore];
            };

            const forwardScore =
                mode === EnumCurrentSessionMode.MODE_FORWARD ?
                    updateScore(scoreState.forwardScore, scoreState.forwardLevel || 2, answer)
                :   scoreState.forwardScore;

            const reverseScore =
                mode === EnumCurrentSessionMode.MODE_REVERSE ?
                    updateScore(scoreState.reverseScore, scoreState.reverseLevel || 2, answer)
                :   scoreState.reverseScore;

            return {
                ...state,
                gameInfo: {
                    ...state.gameInfo,
                    score: {
                        ...state.gameInfo.score,
                        forwardScore,
                        reverseScore,
                    },
                },
            };
        },
        setScores: (state, action: PayloadAction<{ scores: any }>) => {
            return {
                ...state,
                gameInfo: {
                    ...state.gameInfo,
                    scores: action.payload.scores,
                },
            };
        },
        setLevelGame: (
            state,
            action: PayloadAction<{
                level: number;
                mode: EnumCurrentSessionMode;
            }>
        ) => {
            return {
                ...state,
                gameInfo: {
                    ...state.gameInfo,
                    score: {
                        ...state.gameInfo.score,
                        [`${action.payload.mode}Level`]: action.payload.level,
                    },
                },
            };
        },

        changeStatusSession: (
            state,
            action: PayloadAction<
                Partial<Record<"isReverseGame" | "completed", boolean>> & { nBack?: number }
            >
        ) => {
            return {
                ...state,
                gameInfo: {
                    ...state.gameInfo,
                    nBack: action.payload.nBack,
                    isReverseGame: action.payload.isReverseGame || state.gameInfo.isReverseGame,
                    completed: action.payload.completed || state.gameInfo.completed,
                },
            };
        },

        removeCurrentSession: (state) => {
            return (state = { ...initialState });
        },

        setCurrentSpinOffScene: (state, action: PayloadAction<SceneEnum>) => {
            return {
                ...state,
                gameInfo: { ...state.gameInfo, spinoffScene: action.payload },
            };
        },
    },
});

export const {
    setCurrentSession,
    changeStatusSession,
    setSlothScore,
    setScore,
    setScores,
    setLevelGame,
    removeCurrentSession,
    setCurrentSpinOffScene,
} = currentSessionSlice.actions;

export const currentSessionSelector = (state: RootState) => state.session;

export default currentSessionSlice.reducer;
