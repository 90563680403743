import detectiveHappy from "assets/img/common/Detective/let's-play-spinoff.svg";
import detectiveOk from "assets/img/common/Detective/ok.svg";
import detectiveIntroDemo from "assets/img/common/Detective/proof.svg";
import excitedFelix from "assets/img/common/Detective/excited-felix.svg";
import { ActionCard } from "components/ActionCard/ActionCard";
import { RoundButton } from "components/RoundButton/RoundButton";
import { DemoGameFC } from "models/DemoGameFC";
import { TypeGameEnum } from "models/EnumModeGame";
import { EnumPath } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { FC } from "react";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import "./DemoSlothGame.scss";

export const DemoSlothGame: FC<Partial<DemoGameFC>> = ({
  action,
  title,
  link,
  startTutorial,
}) => {
  const { currentScene, type } = useSelector(modeGameSelector);

  return (
    <div data-cy="demo-sloth-game" className="container-demo container-demo-sloth">
      <div className="button-top-close">
        <RoundButton type="close" linkButton={`/${EnumPath.DASHBOARD}`} />
      </div>
      <div className="card-center">
        <ActionCard
          title={title}
          buttonText={`Ok`}
          action={action}
          repeatTutorial={startTutorial}
          showButton={type !== TypeGameEnum.END_MODAL_GAME}
        />
      </div>
      <img
        className={`felix-figure ${
          type.includes(TypeGameEnum.END_MODAL_GAME) ? "thirds" : "whole"
        }`}
        src={
          type.includes(TypeGameEnum.END_MODAL_GAME) ? excitedFelix
          : currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
            detectiveHappy
          : type === TypeGameEnum.START_MODAL_GAME ?
            detectiveOk
          : detectiveIntroDemo
        }
        alt="Felix"
      />
    </div>
  );
};
