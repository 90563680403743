import { TypeGameEnum } from "models/EnumModeGame";
import { EnumPath } from "models/EnumPath";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  modeGameSelector,
  setGame,
  setModeGame,
  setTimerPaused,
} from "store/slices/modeGameSlice";
import { useSnakeGame } from "hook/useSnakeGame";
import StepOne from "./partials/StepOne/StepOne";
import IntroModal from "./partials/IntroModal";
import StepTwo from "./partials/StepTwo/StepTwo";
import { useEffectAudio } from "hook/useEffectAudio";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import "./SnakeGame.scss";
import detectiveSpeak from "assets/img/common/Detective/detective-speak-reverse.svg";
import { RepeatAudioBtn } from "components/RepeatAudioButton/RepeatAudioBtn";

export const SnakeGame = React.memo(() => {
  const dispatch = useDispatch();
  const modeGame = useSelector(modeGameSelector);
  const {
    squareSize,
    onSquareCouplesResponse,
    interactionWarning,
    isErrorWarning,
    currentStep,
    noisecards,
    addNoiseToNoiseResponse,
    interactionWarningTimeOut,
    noiseSequence,
    setNoiseResponse,
    goToStep2,
    goToStep1,
  } = useSnakeGame();

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [modalType, setModalType] = useState<"tutorial" | "playGame">("tutorial");
  const { toggleCurrentMusic, volumeVoice } = useEffectAudio();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [balloonText, setBalloonText] = useState<string[]>([
    "Adesso in ordine tocca le immagini che corrispondo ai suoni che hai ascoltato",
  ]);

  useEffect(() => {
    dispatch(setGame(EnumPath.SNAKE_GAME));
    dispatch(
      setModeGame({
        mode: modeGame.mode,
        type: TypeGameEnum.START_MODAL_DEMO,
      })
    );
    goToStep1();
    setModalType("tutorial");
    setIsModalOpen(true);
    dispatch(setTimerPaused(true));
    toggleCurrentMusic("music", ""); //to turn off background audio
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, modeGame.mode, toggleCurrentMusic]);

  useEffect(() => {
    if (modeGame.type === TypeGameEnum.START_MODAL_GAME) {
      setIsModalOpen(true);
      setModalType("playGame");
    }
  }, [modeGame.type]);

  const handleGoToStep2 = () => {
    setIsTransitioning(true); //transition in progress
    goToStep2();
    setTimeout(() => {
      setIsTransitioning(false); //transition finished
    }, 1000);
  };

  const setGameStatus = (type: "startTraining" | "playGame") => {
    switch (type) {
      case "startTraining":
        dispatch(
          setModeGame({
            mode: modeGame.mode,
            type: TypeGameEnum.TRAINING_TUTORIAL,
          })
        );
        break;
      case "playGame":
        dispatch(
          setModeGame({
            mode: modeGame.mode,
            type: TypeGameEnum.PLAY_GAME,
          })
        );
        dispatch(setTimerPaused(false));
        break;
      default:
        break;
    }
  };

  const onCloseModal = (modalType: "tutorial" | "playGame") => {
    if (modalType === "tutorial") setGameStatus("startTraining");
    if (modalType === "playGame") setGameStatus("playGame");
    interactionWarningTimeOut();
    setIsModalOpen(false);
  };

  const renderRepeatAudio = () => {
    noiseSequence.forEach((audioSrc, index) => {
      setTimeout(() => {
        const sound = new Howl({
          src: [require(`assets/audio/specheed/snake/game/${audioSrc}.m4a`)],
          volume: volumeVoice,
        });
        sound.play();
      }, index * 3000);
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      interactionWarningTimeOut();
    }
  }, [isModalOpen, interactionWarningTimeOut, currentStep]);

  return (
    <div className="container-game snake-game">
      <div className="snake-bg">
        <IntroModal
          isOpen={isModalOpen}
          onClose={() => onCloseModal(modalType)}
          type={modalType}
        />
      </div>

      {currentStep === 1 && (
        <StepOne
          goToStep2={handleGoToStep2}
          noiseSequence={noiseSequence}
          squareSize={squareSize}
          onSquareCouplesResponse={onSquareCouplesResponse}
          interactionWarning={interactionWarning}
          isErrorWarning={isErrorWarning}
        />
      )}
      {!isModalOpen && currentStep === 2 && !isTransitioning ?
        <div className="felix-step-2-container">
          <div className="felix-step-2-content">
            {modalType === "tutorial" && (
              <div className="balloon-container">
                <SimpleCard text={balloonText} bottomTip />
              </div>
            )}
            <img className="felix-step-2-img" src={detectiveSpeak} alt="felix" />
          </div>
          {modalType === "tutorial" && <RepeatAudioBtn action={renderRepeatAudio} />}
        </div>
      : null}
      {currentStep === 2 && !isTransitioning && (
        <StepTwo
          noiseCards={noisecards}
          addNoiseToNoiseResponse={addNoiseToNoiseResponse}
          isTransitioning={isTransitioning}
          interactionWarning={interactionWarning}
          interactionWarningTimeOut={interactionWarningTimeOut}
          isTutorial={modalType === "tutorial"}
          noiseSequence={noiseSequence}
          onBalloonTextChange={setBalloonText}
          setNoiseResponse={setNoiseResponse}
        />
      )}
    </div>
  );
});
