import sibilloSnake from "assets/img/snake/sibillo.png";
import { ActionCard } from "components/ActionCard/ActionCard";

import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { CardIconEnum } from "models/CardIconEnum";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import "./SnakeFwdIntroStart.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * SnakeFwdIntroStart Component
 *
 * This component represents a started chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotFwIntroStartData: SceneDataT = [
  {
    title: "Il caso dei serpenti aggrovigliati",
    text: [
      "Sibillo è un piccolo serpente che vive con la sua numerosa famiglia sulle sponde del fiume più grande della foresta.",
      "Una mattina, al suo risveglio, nota uno strano silenzio. Guardandosi intorno inizia a capire: tutti i suoi amici sono stati aggrovigliati e imbavagliati.",
    ],
    audio: {
      narrator: "snake/narrator-01",
    },
    images: {
      sibillo: sibilloSnake,
    },
  },
];
export const SnakeFwdIntroStart: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const contents = parrotFwIntroStartData;
  const [
    {
      title,
      text,
      images: { sibillo },
    },
  ] = contents;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[contents[0].audio!.narrator]}
        start={Boolean(page)}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [contents, page, setIsFinished]
  );
  return (
    <div
      className={`chapter-started-snake fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      {renderSpeech}
      <div className="card-center">
        <ActionCard
          iconImg={CardIconEnum.LENS}
          data-cy="test-action-card"
          title={title}
          buttonText="Avanti"
          text={text}
          action={action}
        />
      </div>
      <img src={sibillo} className="snake-figure" alt="sibillo on trunk" />
    </div>
  );
};
