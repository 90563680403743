import { EnumGame } from "models/EnumPath";
import { getSkeletonParrotImg } from "assets/img/album/cards/parrots-stickers/skeleton";
import { getStickerParrotImg } from "assets/img/album/cards/parrots-stickers/stickers";
import { getSkeletonToucanImg } from "assets/img/album/cards/toucans-stickers/skeleton";
import { getStickerToucanImg } from "assets/img/album/cards/toucans-stickers/stickers";
import { getSkeletonSlothImg } from "assets/img/album/cards/sloths-stickers/skeleton";
import { getStickerSlothImg } from "assets/img/album/cards/sloths-stickers/stickers";
import { getSkeletonSnakeImg } from "assets/img/album/cards/snakes-stickers/skeleton";
import { getStickerSnakeImg } from "assets/img/album/cards/snakes-stickers/stickers";

export const albumCards = [
    {
        category: EnumGame.PARROT_GAME,
        cards: [
            {
                id: 1,
                pathSkeleton: getSkeletonParrotImg("1"),
                pathSticker: getStickerParrotImg("1"),
            },
            {
                id: 2,
                pathSkeleton: getSkeletonParrotImg("2"),
                pathSticker: getStickerParrotImg("2"),
            },
            {
                id: 3,
                pathSkeleton: getSkeletonParrotImg("3"),
                pathSticker: getStickerParrotImg("3"),
            },
            {
                id: 4,
                pathSkeleton: getSkeletonParrotImg("4"),
                pathSticker: getStickerParrotImg("4"),
            },
            {
                id: 5,
                pathSkeleton: getSkeletonParrotImg("5"),
                pathSticker: getStickerParrotImg("5"),
            },
            {
                id: 6,
                pathSkeleton: getSkeletonParrotImg("6"),
                pathSticker: getStickerParrotImg("6"),
            },
            {
                id: 7,
                pathSkeleton: getSkeletonParrotImg("7"),
                pathSticker: getStickerParrotImg("7"),
            },
            {
                id: 8,
                pathSkeleton: getSkeletonParrotImg("8"),
                pathSticker: getStickerParrotImg("8"),
            },
            {
                id: 9,
                pathSkeleton: getSkeletonParrotImg("9"),
                pathSticker: getStickerParrotImg("9"),
            },
            {
                id: 10,
                pathSkeleton: getSkeletonParrotImg("10"),
                pathSticker: getStickerParrotImg("10"),
            },
            {
                id: 11,
                pathSkeleton: getSkeletonParrotImg("11"),
                pathSticker: getStickerParrotImg("11"),
            },
            {
                id: 12,
                pathSkeleton: getSkeletonParrotImg("12"),
                pathSticker: getStickerParrotImg("12"),
            },
            {
                id: 13,
                pathSkeleton: getSkeletonParrotImg("13"),
                pathSticker: getStickerParrotImg("13"),
            },
            {
                id: 14,
                pathSkeleton: getSkeletonParrotImg("14"),
                pathSticker: getStickerParrotImg("14"),
            },
            {
                id: 15,
                pathSkeleton: getSkeletonParrotImg("15"),
                pathSticker: getStickerParrotImg("15"),
            },
            {
                id: 16,
                pathSkeleton: getSkeletonParrotImg("16"),
                pathSticker: getStickerParrotImg("16"),
            },
            {
                id: 17,
                pathSkeleton: getSkeletonParrotImg("17"),
                pathSticker: getStickerParrotImg("17"),
            },
            {
                id: 18,
                pathSkeleton: getSkeletonParrotImg("18"),
                pathSticker: getStickerParrotImg("18"),
            },
            {
                id: 19,
                pathSkeleton: getSkeletonParrotImg("19"),
                pathSticker: getStickerParrotImg("19"),
            },
            {
                id: 20,
                pathSkeleton: getSkeletonParrotImg("20"),
                pathSticker: getStickerParrotImg("20"),
            },
            {
                id: 21,
                pathSkeleton: getSkeletonParrotImg("21"),
                pathSticker: getStickerParrotImg("21"),
            },
            {
                id: 22,
                pathSkeleton: getSkeletonParrotImg("22"),
                pathSticker: getStickerParrotImg("22"),
            },
            {
                id: 23,
                pathSkeleton: getSkeletonParrotImg("23"),
                pathSticker: getStickerParrotImg("23"),
            },
            {
                id: 24,
                pathSkeleton: getSkeletonParrotImg("24"),
                pathSticker: getStickerParrotImg("24"),
            },
            {
                id: 25,
                pathSkeleton: getSkeletonParrotImg("25"),
                pathSticker: getStickerParrotImg("25"),
            },
        ],
    },
    {
        category: EnumGame.TOUCAN_GAME,
        cards: [
            {
                id: 1,
                pathSkeleton: getSkeletonToucanImg("1"),
                pathSticker: getStickerToucanImg("1"),
            },
            {
                id: 2,
                pathSkeleton: getSkeletonToucanImg("2"),
                pathSticker: getStickerToucanImg("2"),
            },
            {
                id: 3,
                pathSkeleton: getSkeletonToucanImg("3"),
                pathSticker: getStickerToucanImg("3"),
            },
            {
                id: 4,
                pathSkeleton: getSkeletonToucanImg("4"),
                pathSticker: getStickerToucanImg("4"),
            },
            {
                id: 5,
                pathSkeleton: getSkeletonToucanImg("1"),
                pathSticker: getStickerToucanImg("5"),
            },
            {
                id: 6,
                pathSkeleton: getSkeletonToucanImg("2"),
                pathSticker: getStickerToucanImg("6"),
            },
            {
                id: 7,
                pathSkeleton: getSkeletonToucanImg("3"),
                pathSticker: getStickerToucanImg("7"),
            },
            {
                id: 8,
                pathSkeleton: getSkeletonToucanImg("4"),
                pathSticker: getStickerToucanImg("8"),
            },
            {
                id: 9,
                pathSkeleton: getSkeletonToucanImg("1"),
                pathSticker: getStickerToucanImg("9"),
            },
            {
                id: 10,
                pathSkeleton: getSkeletonToucanImg("2"),
                pathSticker: getStickerToucanImg("10"),
            },
            {
                id: 11,
                pathSkeleton: getSkeletonToucanImg("3"),
                pathSticker: getStickerToucanImg("11"),
            },
            {
                id: 12,
                pathSkeleton: getSkeletonToucanImg("4"),
                pathSticker: getStickerToucanImg("12"),
            },
            {
                id: 13,
                pathSkeleton: getSkeletonToucanImg("1"),
                pathSticker: getStickerToucanImg("13"),
            },
            {
                id: 14,
                pathSkeleton: getSkeletonToucanImg("2"),
                pathSticker: getStickerToucanImg("14"),
            },
            {
                id: 15,
                pathSkeleton: getSkeletonToucanImg("3"),
                pathSticker: getStickerToucanImg("15"),
            },
            {
                id: 16,
                pathSkeleton: getSkeletonToucanImg("4"),
                pathSticker: getStickerToucanImg("16"),
            },
            {
                id: 17,
                pathSkeleton: getSkeletonToucanImg("1"),
                pathSticker: getStickerToucanImg("17"),
            },
            {
                id: 18,
                pathSkeleton: getSkeletonToucanImg("2"),
                pathSticker: getStickerToucanImg("18"),
            },
            {
                id: 19,
                pathSkeleton: getSkeletonToucanImg("3"),
                pathSticker: getStickerToucanImg("19"),
            },
            {
                id: 20,
                pathSkeleton: getSkeletonToucanImg("4"),
                pathSticker: getStickerToucanImg("20"),
            },
            {
                id: 21,
                pathSkeleton: getSkeletonToucanImg("1"),
                pathSticker: getStickerToucanImg("21"),
            },
            {
                id: 22,
                pathSkeleton: getSkeletonToucanImg("2"),
                pathSticker: getStickerToucanImg("22"),
            },
            {
                id: 23,
                pathSkeleton: getSkeletonToucanImg("3"),
                pathSticker: getStickerToucanImg("23"),
            },
            {
                id: 24,
                pathSkeleton: getSkeletonToucanImg("4"),
                pathSticker: getStickerToucanImg("24"),
            },
            {
                id: 25,
                pathSkeleton: getSkeletonToucanImg("1"),
                pathSticker: getStickerToucanImg("25"),
            },
        ],
    },
    {
        category: EnumGame.SLOTH_GAME,
        cards: [
            {
                id: 1,
                pathSkeleton: getSkeletonSlothImg("1"),
                pathSticker: getStickerSlothImg("1"),
            },
            {
                id: 2,
                pathSkeleton: getSkeletonSlothImg("2"),
                pathSticker: getStickerSlothImg("2"),
            },
            {
                id: 3,
                pathSkeleton: getSkeletonSlothImg("3"),
                pathSticker: getStickerSlothImg("3"),
            },
            {
                id: 4,
                pathSkeleton: getSkeletonSlothImg("4"),
                pathSticker: getStickerSlothImg("4"),
            },
            {
                id: 5,
                pathSkeleton: getSkeletonSlothImg("5"),
                pathSticker: getStickerSlothImg("5"),
            },
            {
                id: 6,
                pathSkeleton: getSkeletonSlothImg("6"),
                pathSticker: getStickerSlothImg("6"),
            },
            {
                id: 7,
                pathSkeleton: getSkeletonSlothImg("7"),
                pathSticker: getStickerSlothImg("7"),
            },
            {
                id: 8,
                pathSkeleton: getSkeletonSlothImg("8"),
                pathSticker: getStickerSlothImg("8"),
            },
            {
                id: 9,
                pathSkeleton: getSkeletonSlothImg("9"),
                pathSticker: getStickerSlothImg("9"),
            },
            {
                id: 10,
                pathSkeleton: getSkeletonSlothImg("1"),
                pathSticker: getStickerSlothImg("10"),
            },
            {
                id: 11,
                pathSkeleton: getSkeletonSlothImg("2"),
                pathSticker: getStickerSlothImg("11"),
            },
            {
                id: 12,
                pathSkeleton: getSkeletonSlothImg("3"),
                pathSticker: getStickerSlothImg("12"),
            },
            {
                id: 13,
                pathSkeleton: getSkeletonSlothImg("4"),
                pathSticker: getStickerSlothImg("13"),
            },
            {
                id: 14,
                pathSkeleton: getSkeletonSlothImg("5"),
                pathSticker: getStickerSlothImg("14"),
            },
            {
                id: 15,
                pathSkeleton: getSkeletonSlothImg("6"),
                pathSticker: getStickerSlothImg("15"),
            },
            {
                id: 16,
                pathSkeleton: getSkeletonSlothImg("7"),
                pathSticker: getStickerSlothImg("16"),
            },
            {
                id: 17,
                pathSkeleton: getSkeletonSlothImg("8"),
                pathSticker: getStickerSlothImg("17"),
            },
            {
                id: 18,
                pathSkeleton: getSkeletonSlothImg("9"),
                pathSticker: getStickerSlothImg("18"),
            },
            {
                id: 19,
                pathSkeleton: getSkeletonSlothImg("1"),
                pathSticker: getStickerSlothImg("19"),
            },
            {
                id: 20,
                pathSkeleton: getSkeletonSlothImg("2"),
                pathSticker: getStickerSlothImg("20"),
            },
            {
                id: 21,
                pathSkeleton: getSkeletonSlothImg("3"),
                pathSticker: getStickerSlothImg("21"),
            },
            {
                id: 22,
                pathSkeleton: getSkeletonSlothImg("4"),
                pathSticker: getStickerSlothImg("22"),
            },
            {
                id: 23,
                pathSkeleton: getSkeletonSlothImg("5"),
                pathSticker: getStickerSlothImg("23"),
            },
            {
                id: 24,
                pathSkeleton: getSkeletonSlothImg("6"),
                pathSticker: getStickerSlothImg("24"),
            },
            {
                id: 25,
                pathSkeleton: getSkeletonSlothImg("7"),
                pathSticker: getStickerSlothImg("25"),
            },
        ],
    },
    {
        category: EnumGame.SNAKE_GAME,
        cards: [
            {
                id: 1,
                pathSkeleton: getSkeletonSnakeImg("1"),
                pathSticker: getStickerSnakeImg("1"),
            },
            {
                id: 2,
                pathSkeleton: getSkeletonSnakeImg("2"),
                pathSticker: getStickerSnakeImg("2"),
            },
            {
                id: 3,
                pathSkeleton: getSkeletonSnakeImg("3"),
                pathSticker: getStickerSnakeImg("3"),
            },
            {
                id: 4,
                pathSkeleton: getSkeletonSnakeImg("4"),
                pathSticker: getStickerSnakeImg("4"),
            },
            {
                id: 5,
                pathSkeleton: getSkeletonSnakeImg("5"),
                pathSticker: getStickerSnakeImg("5"),
            },
            {
                id: 6,
                pathSkeleton: getSkeletonSnakeImg("6"),
                pathSticker: getStickerSnakeImg("6"),
            },
            {
                id: 7,
                pathSkeleton: getSkeletonSnakeImg("7"),
                pathSticker: getStickerSnakeImg("7"),
            },
            {
                id: 8,
                pathSkeleton: getSkeletonSnakeImg("8"),
                pathSticker: getStickerSnakeImg("8"),
            },
            {
                id: 9,
                pathSkeleton: getSkeletonSnakeImg("9"),
                pathSticker: getStickerSnakeImg("9"),
            },
            {
                id: 10,
                pathSkeleton: getSkeletonSnakeImg("10"),
                pathSticker: getStickerSnakeImg("10"),
            },
            {
                id: 11,
                pathSkeleton: getSkeletonSnakeImg("11"),
                pathSticker: getStickerSnakeImg("11"),
            },
            {
                id: 12,
                pathSkeleton: getSkeletonSnakeImg("12"),
                pathSticker: getStickerSnakeImg("12"),
            },
            {
                id: 13,
                pathSkeleton: getSkeletonSnakeImg("13"),
                pathSticker: getStickerSnakeImg("13"),
            },
            {
                id: 14,
                pathSkeleton: getSkeletonSnakeImg("14"),
                pathSticker: getStickerSnakeImg("14"),
            },
            {
                id: 15,
                pathSkeleton: getSkeletonSnakeImg("15"),
                pathSticker: getStickerSnakeImg("15"),
            },
            {
                id: 16,
                pathSkeleton: getSkeletonSnakeImg("16"),
                pathSticker: getStickerSnakeImg("16"),
            },
            {
                id: 17,
                pathSkeleton: getSkeletonSnakeImg("17"),
                pathSticker: getStickerSnakeImg("17"),
            },
            {
                id: 18,
                pathSkeleton: getSkeletonSnakeImg("18"),
                pathSticker: getStickerSnakeImg("18"),
            },
            {
                id: 19,
                pathSkeleton: getSkeletonSnakeImg("19"),
                pathSticker: getStickerSnakeImg("19"),
            },
            {
                id: 20,
                pathSkeleton: getSkeletonSnakeImg("20"),
                pathSticker: getStickerSnakeImg("20"),
            },
            {
                id: 21,
                pathSkeleton: getSkeletonSnakeImg("21"),
                pathSticker: getStickerSnakeImg("21"),
            },
            {
                id: 22,
                pathSkeleton: getSkeletonSnakeImg("22"),
                pathSticker: getStickerSnakeImg("22"),
            },
            {
                id: 23,
                pathSkeleton: getSkeletonSnakeImg("23"),
                pathSticker: getStickerSnakeImg("23"),
            },
            {
                id: 24,
                pathSkeleton: getSkeletonSnakeImg("24"),
                pathSticker: getStickerSnakeImg("24"),
            },
            {
                id: 25,
                pathSkeleton: getSkeletonSnakeImg("25"),
                pathSticker: getStickerSnakeImg("25"),
            },
        ],
    },
];
