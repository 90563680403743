import { RoundButton } from "components/RoundButton/RoundButton";
import { Timer } from "components/Timer/Timer";
import useEditSessionGame from "hook/useEditSessionGame";
import { ModeGameEnum, TypeGameEnum } from "models/EnumModeGame";
import { EnumGame, EnumPath, EnumPlayMode } from "models/EnumPath";
import { WrapperGameFC } from "models/WrapperGameFC";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeCurrentSession } from "store/slices/currentSessionSlice";
import {
  modeGameSelector,
  setGame,
  setIsFirstGame,
  setModeGame,
  setSnakeStep,
} from "store/slices/modeGameSlice";
import "./WrapperGame.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useGamePath } from "hook/useGamePath";
import useGetCurrentUser from "hook/useGetCurrentUser";

/**
 * WrapperGame Component: Represents a wrapper for a game interface.
 *
 * @param {Object} props - The component's properties.
 * @param {ReactNode} props.children - The child components to be displayed within the wrapper.
 * @param {ReactNode} props.timer - The timer game.
 * @returns {JSX.Element} - The WrapperGame component.
 */
export const WrapperGame: FC<WrapperGameFC> = React.memo(
  ({ children, timerGame, title, description, audio, gameName }) => {
    const { updateCurrentSession } = useEditSessionGame();
    const modeGame = useSelector(modeGameSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { gamePathScene, wichGameModeIs } = useGamePath(gameName);
    const isPlayNormal = wichGameModeIs(EnumPlayMode.PLAY_NORMAL);
    const isPlayReverse = wichGameModeIs(EnumPlayMode.PLAY_REVERSE);

    const { hasPlayedGame } = useGetCurrentUser();

    const [startSpeech, setStartSpeech] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
      if (gameName === EnumGame.PARROT_GAME) return;
      isFinished &&
        dispatch(
          setModeGame({
            type: TypeGameEnum.SPEECH_GAME_TUTORIAL,
            mode: modeGame.mode,
          })
        );
    }, [dispatch, gameName, isFinished, modeGame.mode]);

    const timeUp = async () => {
      if (isPlayNormal) {
        dispatch(setIsFirstGame(false));

        if (modeGame.game === EnumPath.SNAKE_GAME) dispatch(setSnakeStep(null));

        await updateCurrentSession({ isReverseGame: true, completed: false });
        navigate(
          hasPlayedGame(gameName) ? gamePathScene.spinOffHalf : gamePathScene.reversPlay
        );
      }
      if (isPlayReverse) {
        await updateCurrentSession({ isReverseGame: false, completed: true });
        navigate(
          hasPlayedGame(gameName) ? gamePathScene.spinOffEnding : gamePathScene.firstEnding
        );
      }
    };

    const goBack = () => {
      dispatch(
        setModeGame({
          type: TypeGameEnum.START_MODAL_DEMO,
          mode: ModeGameEnum.FORWARD_GAME,
        })
      );
      dispatch(removeCurrentSession());
      navigate(`/${EnumPath.DASHBOARD}`);
      dispatch(setGame(""));
    };

    useEffect(() => {
      modeGame.type === TypeGameEnum.TRAINING_TUTORIAL && setStartSpeech(true);
      (modeGame.type === TypeGameEnum.PLAY_GAME ||
        modeGame.type === TypeGameEnum.SPEECH_GAME) &&
        setStartSpeech(false);
    }, [modeGame]);

    const renderAudio = useMemo(
      () => (
        <SpeechAudio
          isRepeatHidden={true}
          audioFiles={[`${audio.narrator}`]}
          start={startSpeech}
          setFinished={() => setIsFinished(true)}
        />
      ),
      [audio.narrator, startSpeech]
    );

    return (
      <div className="container-wrapper" data-cy="test-wrapper-game">
        {modeGame.type !== TypeGameEnum.START_MODAL_DEMO &&
          modeGame.type !== TypeGameEnum.END_MODAL_GAME &&
          modeGame.type !== TypeGameEnum.START_MODAL_GAME && (
            <div className="container-wrapper-top">
              <div className="label-game-cont">
                {renderAudio}
                <div className="label-game">
                  <h2 className="label-game-title">{title}</h2>
                  <p className="label-game-paragraph">{description}</p>
                </div>
              </div>
              <div className="timer-close-container">
                {[TypeGameEnum.SPEECH_GAME, TypeGameEnum.PLAY_GAME].includes(modeGame.type) && (
                  <div className="timer-cont">
                    <Timer time={timerGame} timeUp={timeUp} />
                  </div>
                )}
                <RoundButton
                  type="close"
                  action={goBack}
                  linkButton={`/${EnumPath.DASHBOARD}`}
                />
              </div>
            </div>
          )}
        <div className="container-wrapper-center">{children}</div>
      </div>
    );
  }
);
