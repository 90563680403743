import "./SnakeEndSceneTwo.scss";
import { ActionCard } from "components/ActionCard/ActionCard";
import { useGamePath } from "hook/useGamePath";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { CardIconEnum } from "models/CardIconEnum";
import { ChapterSceneFC, EndSceneType } from "models/ChapterSceneFC";
import { EnumGame } from "models/EnumPath";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import { getEndingScene } from "utils/getEndingScene";
import sibilloSnake from "assets/img/snake/sibillo.png";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * SnakeEndSceneTwo Component
 *
 * This component represents the end of a chapter in the story.
 * It displays a fading-in image of a door.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const snakeEndSceneTwoData: EndSceneType[] = [
  {
    title: "Ce l'hai fatta!",
    scenes: {
      end: {
        text: [
          "Grazie per aver aiutato i miei amici!.",
          "Restano altre coppie da liberare, ogni volta che mi aiuterai collezionerai una nuova figurina per il tuo nuovo album.",
        ],
        audio: "snake/sibillo-end",
      },
      spinoffFirst: {
        text: [
          "Finalmente anche Ziggy e Spike sono liberi.",
          "Ecco la tua figurina, torna a giocare per ottenerne altre!",
        ],
        audio: "snake/sibillo-spinoff-first-end",
      },
      spinoffSecond: {
        text: [
          "Adesso anche Zara e Filo sono liberi!",
          "Ecco un’altra figurina, torna a giocare per ottenerne altre.",
        ],
        audio: "snake/sibillo-spinoff-second-end",
      },
      spinoffDefault: {
        text: [
          "Continua a giocare per liberare gli altri serpenti e collezionare nuove figurine!",
        ],
        audio: "snake/sibillo-spinoff-default-end",
      },
      last: {
        text: [
          "Hai salvato tutti i miei amici.",
          "Sei a un passo dalla risoluzione di tutti i casi! Ottieni l’ultima figurina e scopri il resto del messaggio",
        ],
        audio: "snake/sibillo-end-last",
      },
    },
    images: {
      sibillo: sibilloSnake,
    },
  },
];

export const SnakeEndSceneTwo: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { currentSceneId } = useGamePath(EnumGame.SNAKE_GAME);
  const { getGameProgress } = useGetCurrentUser();
  const progress = getGameProgress(EnumGame.SNAKE_GAME);
  const sceneData = getEndingScene({
    id: currentSceneId,
    contents: snakeEndSceneTwoData[0],
    progress,
  });

  const {
    title,
    images: { sibillo },
  } = snakeEndSceneTwoData[0];
  const currentGameProgress = getGameProgress(EnumGame.SNAKE_GAME);

  const nextHandle = () => {
    action && action();
  };

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[sceneData.audio]}
        start={Boolean(page)}
        //TODO: quando avremo il flusso intero con il gioco, capire se ci serviranno i delay
        // endDelay={1000}
        // startDelay={1000}
      />
    ),
    [sceneData.audio, page]
  );

  return (
    <div
      className={`snake-end-two-container snake-bg fade-in-image 
        ${getClasses({
          "fade-out-image": out,
        })}
      `}
    >
      {renderSpeech}
      <div className="card-center">
        <ActionCard
          iconImg={CardIconEnum.GIFT}
          title={title}
          isEndGame
          text={sceneData.text}
          buttonText={currentGameProgress === 0 ? "vai all'album" : "ottieni il premio"}
          action={nextHandle}
        />
      </div>
      <img src={sibillo} className="snake-figure" alt="sibillo on trunk" />
    </div>
  );
};
