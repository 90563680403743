import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Howl } from "howler";
import { RoundButton } from "components/RoundButton/RoundButton";
import { useEffectAudio } from "hook/useEffectAudio";
import { TypeGameEnum } from "models/EnumModeGame";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import { EnumPath } from "models/EnumPath";
import { EnumSecondGameAudio } from "models/EnumSecondGameSquare";
import { RepeatAudioBtn } from "components/RepeatAudioButton/RepeatAudioBtn";

interface SpeechAudioFC {
  audioFiles: string[];
  notVisible?: boolean;
  start?: boolean;
  setFinished?: () => void;
  endDelay?: number;
  startDelay?: number;
  isRepeatHidden?: boolean;
}

export const SpeechAudio: FC<Partial<SpeechAudioFC>> = ({
  audioFiles = [],
  start,
  notVisible,
  setFinished,
  endDelay = 0,
  startDelay,
  isRepeatHidden = false,
}) => {
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [shouldStart, setShouldStart] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const { volumeVoice } = useEffectAudio();
  const toSpeak = audioFiles[currentAudioIndex];

  const modeGame = useSelector(modeGameSelector);
  const isToucanTutorial =
    modeGame.game === EnumPath.TOUCAN_GAME && modeGame.type === TypeGameEnum.PLAYING_TUTORIAL;

  const handlePauseAfterAudio = (callback: () => void, delay: number) => {
    if (delay > 0)
      setTimeout(() => {
        callback();
      }, delay);
    else callback();
  };

  const tutorialSequenceAudio = [
    EnumSecondGameAudio.APPLE,
    EnumSecondGameAudio.SUN,
    EnumSecondGameAudio.DOG,
    EnumSecondGameAudio.POT,
  ];

  const handleReplayToucanTutorial = () => {
    if (isToucanTutorial) {
      if (isPlaying) return;
      tutorialSequenceAudio.forEach((audioSrc, index) => {
        setTimeout(() => {
          const sound = new Howl({
            onplay: () => setIsPlaying(true),
            onend: () => setTimeout(() => setIsPlaying(false), 4000),
            onstop: () => setIsPlaying(false),
            src: [require(`assets/audio/specheed/${audioSrc}.m4a`)],
            volume: volumeVoice,
          });
          sound.play();
        }, index * 1000); // Aggiunge un piccolo ritardo tra i file audio
      });
    } else {
      setCurrentAudioIndex(0);
      audio && audio?.play();
    }
  };

  const handleSetIsFinished = useCallback(() => {
    if (audioFiles.length === 1 || currentAudioIndex === audioFiles.length - 1) {
      setFinished && setFinished();
    } else {
      setCurrentAudioIndex((prevIndex) => {
        return prevIndex + 1;
      });
    }
  }, [audioFiles, currentAudioIndex, setFinished]);

  const audio = useMemo(() => {
    if (!toSpeak) return null;

    try {
      const src = require(`assets/audio/specheed/${toSpeak}.m4a`);

      return new Howl({
        src,
        volume: volumeVoice,
        onplay: () => setIsPlaying(true),
        onend: () => {
          handlePauseAfterAudio(() => {
            handleSetIsFinished();
          }, endDelay);
        },
        onstop: () => setIsPlaying(false),
      });
    } catch (error) {
      console.error("Audio file not found:", toSpeak);

      return null;
    }
  }, [handleSetIsFinished, endDelay, toSpeak, volumeVoice]);

  useEffect(() => {
    if (start) {
      if (startDelay !== undefined) {
        setTimeout(() => setShouldStart(true), startDelay);
      } else {
        setShouldStart(true);
      }
    } else {
      setShouldStart(false);
    }
  }, [start, startDelay]);

  useEffect(() => {
    if (!audio) return;
    if (audioFiles.length > 0 && shouldStart && !audio.playing()) audio.play();

    return () => {
      audio.stop();
    };
  }, [audio, audioFiles, currentAudioIndex, shouldStart, start]);

  useEffect(() => {
    if (!audio) return;
    audio.volume(volumeVoice);
  }, [audio, volumeVoice]);

  return (
    <div>
      {notVisible || (
        <RoundButton
          type="replay"
          action={
            isPlaying ?
              () => audio?.stop()
            : () => {
                setCurrentAudioIndex(0);
                audio && audio?.play();
              }
          }
        />
      )}
      {isToucanTutorial && (
        <RepeatAudioBtn isHidden={isRepeatHidden} action={handleReplayToucanTutorial} />
      )}
    </div>
  );
};
