import React, { FC, useCallback, useEffect, useMemo } from "react";
import "./GameAlbum.scss";
import { ChapterSceneFC } from "models/ChapterSceneFC";
import getClasses from "utils/getClasses";
import detectiveFeedBack from "assets/img/common/Detective/feedback.svg";
import foreGroundPlants from "assets/img/parrot/elements/plant-end-game.svg";
import plant from "assets/img/parrot/elements/plant-parrots.svg";
import palmleft from "assets/img/toucan/elements/palm-left.svg";
import branchFlower from "assets/img/toucan/elements/branch-left-with-flower.svg";
import rock from "assets/img/sloth/elements/rock.svg";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

import useGetCurrentUser from "hook/useGetCurrentUser";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SecretMessage } from "components/SecretMessage/SecretMessage";
import { ActionCard } from "components/ActionCard/ActionCard";
import { useLocation, useNavigate } from "react-router-dom";
import { EnumGame, EnumPath, EnumSceneFlow } from "models/EnumPath";
import Album from "components/Album/Album";
import { gameCardMenu } from "data/gameCardMenu";
import { SceneEnum } from "models/EnumScene";

/**
 * ParrotEndSceneFour Component
 *
 * This component represents the fifth and final part of the end of a chapter in the story.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The rendered component.
 */

const content = {
  firstPlay: [
    { text: "" },
    {
      title: "Detective Felix",
      text: ["Buone notizie! I colpevoli hanno lasciato un messaggio. Vai a vederlo!"],
      audio: "album/detective-felix-album-1",
    },
    {
      text: ["Ogni volta che completerai un album, una parte del messaggio sarà svelata."],
      audio: "album/narrator-album-3",
    },
    {
      text: ["Torna a giocare per completare l’album e decifrare il messaggio"],
      audio: "album/detective-felix-album-4",
    },
  ],
  goal: [
    {},
    {
      text: "Ci siamo! È il momento di decifrare una frase del messaggio",
      audio: "album/detective-felix-goal-2",
    },
    { audio: "" },
    {
      text: "Completa gli album degli altri casi per continuare a decifrare il messaggio!",
      audio: "album/detective-felix-goal-4",
    },
  ],
  extraGame: {
    text: "Complimenti! Continua a giocare",
    audio: "album/detective-felix-extra",
  },
  complitedAllGames: [
    {},
    {
      text: "Hai decifrato tutte le frasi! È arrivato finalmente il momento di svelare i colpevoli!",
      audio: "album/detective-felix-all-sentences-revealed",
    },
  ],
};

//function to retrieve current scene data based on id

export const GameAlbum: FC<Partial<ChapterSceneFC>> = React.memo(
  ({ out, page = 1, action }) => {
    const location = useLocation();
    const gameName = location.pathname.split("/")[2] as EnumGame;
    const gameInfoMap = {
      [EnumGame.PARROT_GAME]: { bg: "parrot-bg", id: 0 },
      [EnumGame.TOUCAN_GAME]: { bg: "toucan-bg", id: 1 },
      [EnumGame.SLOTH_GAME]: { bg: "sloth-bg", id: 2 },
      [EnumGame.SNAKE_GAME]: { bg: "snake-bg", id: 3 },
    };
    const navigate = useNavigate();
    const { hasPlayedGameOnce, getGameProgress, hasCompletedAllGames } = useGetCurrentUser();
    const progress = getGameProgress(gameName);
    const isGoal = progress === 25;
    const isExtraPlay = progress > 25;

    useEffect(() => {
      if (page === 4 && hasCompletedAllGames && !isExtraPlay)
        navigate(
          `/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${EnumPath.SCENE}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`
        );
      if (action && isExtraPlay && page === 1) action(4);
      if (action && !hasPlayedGameOnce && page === 2 && !isGoal && !isExtraPlay) {
        action(4);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPlayedGameOnce, isGoal, page]);

    const sceneData = content.firstPlay;
    const { title, text, audio } = sceneData[page - 1] || {};
    const goalData = content.goal[page - 1] || {};
    const extraGameData = content.extraGame;
    const completedAll = content.complitedAllGames[page - 1] || {};
    // TODO - Bisogna capire se l'autoplay è richiesto in questo componente perché con la logica di skip delle scene causa loop
    // const { setIsFinished } = useSceneAutoPlay({
    //   page,
    //   contents: sceneData as unknown as SceneDataT,
    //   action: action!,
    //   avoid: [4],
    // });
    const getAudioFile = useCallback(() => {
      if (hasCompletedAllGames) {
        return completedAll.audio;
      }
      if ([2, 3, 4].includes(page) && isGoal) {
        return goalData?.audio;
      }
      if (page === 4 && isExtraPlay && extraGameData.audio) {
        return extraGameData?.audio;
      }
      return audio || "";
    }, [
      audio,
      completedAll.audio,
      extraGameData.audio,
      goalData.audio,
      hasCompletedAllGames,
      isExtraPlay,
      isGoal,
      page,
    ]);

    const renderAudio = useMemo(
      () => (
        <SpeechAudio
          start
          audioFiles={[getAudioFile() || ""]}
          notVisible
          // setFinished={() => setIsFinished(true)}
        />
      ),
      [getAudioFile]
    );

    const getImageData = () => {
      switch (gameName) {
        case EnumGame.PARROT_GAME:
          const parrotsrc = page === 4 ? plant : foreGroundPlants;
          const parrotClassNames = page === 4 ? "plants-img-parrot-5" : "plants-img-parrot";
          return { classNames: parrotClassNames, src: parrotsrc };
        case EnumGame.TOUCAN_GAME:
          const toucanSrc = page === 4 ? branchFlower : palmleft;
          const toucanClassNames = page === 4 ? "plants-img-toucan-5" : "plants-img-toucan";
          return { classNames: toucanClassNames, src: toucanSrc };
        case EnumGame.SLOTH_GAME:
          const slothSrc = page === 4 && rock;
          return { classNames: "rock-img-sloth", src: slothSrc };
        default:
          return { classNames: "", src: "" };
      }
    };

    const imageData = getImageData();
    return (
      <div
        className={`game-album ${gameInfoMap[gameName].bg} fade-in-image ${getClasses({
          "fade-out-image": out,
        })}`}
      >
        {renderAudio}
        {[1, 2].includes(page) && (
          <Album
            gameName={gameCardMenu[gameInfoMap[gameName].id].title}
            progress={progress}
            goal={25}
            categoryName={gameName}
          />
        )}
        {page === 3 && <SecretMessage startSpeech={isGoal} />}
        {page === 4 && (
          <div className="card-center message-last-page">
            <ActionCard
              title={
                (isGoal && goalData.text) || (isExtraPlay && extraGameData.text) || text[0]
              }
              buttonText="Ok"
              secondaryButton={
                isExtraPlay ?
                  {
                    label: "Vai all'album",
                    action: () => navigate("/games/album"),
                  }
                : undefined
              }
              action={action}
            />
          </div>
        )}
        {!!imageData.src && (
          <img
            className={` ${imageData.classNames} fade-in-image`}
            src={imageData.src}
            alt="plants on foreground"
          />
        )}
        <div className={`balloon-container balloon-${page}`}>
          {[2, ...[isGoal ? null : 3]].includes(page) && (
            <SimpleCard
              title={title}
              text={[
                (hasCompletedAllGames && completedAll.text) ||
                  (isGoal && goalData.text) ||
                  (text && text[0]) ||
                  "",
              ]}
              bottomTip={page === 3}
            />
          )}
        </div>
        {[2, 4].includes(page) && (
          <img className="img-felix-1" src={detectiveFeedBack} alt="Felix" />
        )}
      </div>
    );
  }
);
