import { EnumPath, EnumSceneFlow } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";

import { GameIntro } from "pages/private/interludes/genericsInterludes/GameIntro/GameIntro";

import { buttonLabels, ComponentsData } from "./declaration";
import { parrotContent } from "./parrot.content";
import { toucanContent } from "./toucan.content";
import { slothContent } from "./sloth.content";
import { snakeContent } from "./snake.content";

import { FinalMessageFwdOne } from "pages/private/interludes/genericsInterludes/Message/FinalMessageFwdOne/FinalMessageFwdOne";
import { FinalMessageFwdTwo } from "pages/private/interludes/genericsInterludes/Message/FinalMessageFwdTwo/FinalMessageFwdTwo";
import { FinalMessageFwdThree } from "pages/private/interludes/genericsInterludes/Message/FinalMessageFwdThree/FinalMessageFwdThree";

const { skip, next } = buttonLabels;

export const componentsData: ComponentsData = {
    intro: {
        forward: {
            [SceneEnum.FORWARD_ONE]: {
                component: GameIntro,
                multipage: 2,
                actions: {
                    skip: {
                        label: skip,
                        path: `/${EnumPath.DASHBOARD}/${EnumPath.GAME_CHAPTERS}`,
                    },
                    next: {
                        label: "",
                        path: `/${EnumPath.DASHBOARD}/${EnumPath.GAME_CHAPTERS}`,
                    },
                },
            },
        },
    },
    parrot: parrotContent,
    toucan: toucanContent,
    sloth: slothContent,
    snake: snakeContent,
    "final-message": {
        forward: {
            [SceneEnum.FORWARD_ONE]: {
                component: FinalMessageFwdOne,
                multipage: 2,
                actions: {
                    close: true,
                    back: {
                        label: "",
                        path: `/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${EnumPath.SCENE}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                    },
                    next: {
                        label: next,
                        path: `/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${EnumPath.SCENE}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                    },
                },
            },
            [SceneEnum.FORWARD_TWO]: {
                component: FinalMessageFwdTwo,
                multipage: 2,
                actions: {
                    close: true,
                    back: {
                        label: "",
                        path: `/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${EnumPath.SCENE}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                    },
                    next: {
                        label: next,
                        path: `/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${EnumPath.SCENE}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_THREE}`,
                    },
                },
            },
            [SceneEnum.FORWARD_THREE]: {
                component: FinalMessageFwdThree,
                multipage: 4,
                actions: {
                    close: true,
                    back: {
                        label: "",
                        path: `/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${EnumPath.SCENE}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                    },
                    next: {
                        label: next,
                        path: `/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${EnumPath.SCENE}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                    },
                },
            },
        },
    },
};
