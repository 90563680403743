import React, { FC, useEffect, useMemo } from "react";
import "./FinalMessageFwdOne.scss";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import getClasses from "utils/getClasses";
import detectiveFeedBack from "assets/img/common/Detective/feedback.svg";
import detectiveAdvice from "assets/img/common/Detective/detective-advice-full.svg";
import foreGroundPlants from "assets/img/parrot/elements/plant-end-game.svg";
import plant from "assets/img/parrot/elements/plant-parrots.svg";
import palmleft from "assets/img/toucan/elements/palm-left.svg";
import branchFlower from "assets/img/toucan/elements/branch-left-with-flower.svg";
import rock from "assets/img/sloth/elements/rock.svg";

import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SecretMessage } from "components/SecretMessage/SecretMessage";

import { EnumGame } from "models/EnumPath";

import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";

const content: SceneDataT = [
  {
    title: "Detective Felix",
    text: [
      "Abbiamo finalmente svelato l’identità dei colpevoli! Sono stati i bracconieri a rapire gli animali e danneggiare la foresta.",
    ],
    images: {},
    audio: { felix: "message/felix-message-1" },
  },
  {
    title: "Detective Felix",
    text: [
      "Si tratta di persone molto cattive, e non si trovano solo a Eufauna ma in tantissimi altri luoghi incontaminati e bellissimi del pianeta, come boschi e foreste. Rapiscono gli animali per venderli, costringendoli a un destino crudele.",
    ],
    images: {},
    audio: { felix: "message/felix-message-2" },
  },
];

export const FinalMessageFwdOne: FC<Partial<ChapterSceneFC>> = React.memo(
  ({ out, page = 1, action }) => {
    const { game } = useSelector(modeGameSelector);
    const gameName = game as EnumGame;
    const contents = content;
    const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

    const { title, text } = content[page - 1] || {};
    const parrotsrc = page === 4 ? plant : foreGroundPlants;
    const parrotClassNames = page === 4 ? "plants-img-parrot-5" : "plants-img-parrot";
    const getImageData = () => {
      switch (gameName) {
        case EnumGame.PARROT_GAME:
          return { classNames: parrotClassNames, src: parrotsrc, bg: "parrot-bg" };
        case EnumGame.TOUCAN_GAME:
          const toucanSrc = page === 4 ? branchFlower : palmleft;
          const toucanClassNames = page === 4 ? "plants-img-toucan-5" : "plants-img-toucan";
          return { classNames: toucanClassNames, src: toucanSrc, bg: "toucan-bg" };
        case EnumGame.SLOTH_GAME:
          const slothSrc = page === 4 && rock;
          return { classNames: "rock-img-sloth", src: slothSrc, bg: "sloth-bg" };
        default:
          return { classNames: parrotClassNames, src: parrotsrc, bg: "parrot-bg" };
      }
    };

    useEffect(() => {
      if (action && page > 2) action(1);
      if (page === 2) setTimeout(() => action && action(), 14100); //TODO: condizione necessaria per la page 2 di FinalMessageFwdOne
      //  che ha un audio molto lungo
    }, [action, page]);

    const imageData = getImageData();

    const renderAudio = useMemo(
      () => (
        <SpeechAudio
          notVisible
          start={Boolean(page)}
          audioFiles={[
            ...(page === 1 ? [contents[0].audio!.felix] : []),
            ...(page === 2 ? [contents[1].audio!.felix] : []),
          ]}
          setFinished={() => page === 1 && setIsFinished(true)}
        />
      ),
      [contents, page, setIsFinished]
    );

    return (
      <div
        className={`game-album ${imageData.bg} fade-in-image ${getClasses({
          "fade-out-image": out,
        })}`}
      >
        <SecretMessage />
        {renderAudio}

        {!!imageData.src && (
          <img
            className={` ${imageData.classNames} fade-in-image`}
            src={imageData.src}
            alt="plants on foreground"
          />
        )}
        <div className={`balloon-container balloon-${page}`}>
          <SimpleCard size="lg" title={title} text={[(text && text[0]) || ""]} />
        </div>

        <img
          className={`img-felix-${page} fade-in-image`}
          src={page === 2 ? detectiveAdvice : detectiveFeedBack}
          alt="Felix"
        />
      </div>
    );
  }
);
