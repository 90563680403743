import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { TypeGameEnum } from "models/EnumModeGame";
import { useCallback, useMemo, useState } from "react";
import "./StepOne.scss";
import { useDispatch, useSelector } from "react-redux";
import { modeGameSelector, setModeGame } from "store/slices/modeGameSlice";

interface Props {
  squareSize: number[];
  onSquareCouplesResponse: (index: 0 | 1) => boolean;
  interactionWarning: boolean;
  isErrorWarning: boolean;
  noiseSequence: string[];
  goToStep2: () => void;
}

const StepOne = ({
  squareSize,
  onSquareCouplesResponse,
  interactionWarning,
  isErrorWarning,
  noiseSequence,
  goToStep2,
}: Props) => {
  const [errorSquare, setErrorSquare] = useState<0 | 1 | null>(null);
  const modeGame = useSelector(modeGameSelector);
  const dispatch = useDispatch();

  const isTutorial = modeGame.type === TypeGameEnum.PLAYING_TUTORIAL;
  const isTraining =
    modeGame.type === TypeGameEnum.TRAINING_TUTORIAL ||
    modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL;
  const isPlaying = modeGame.type === TypeGameEnum.PLAY_GAME;
  const isAudioPlayable = isPlaying || isTutorial;

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={noiseSequence.map((noise) => `snake/game/${noise}`)}
        start={isAudioPlayable}
        startDelay={1000}
        endDelay={2000}
        setFinished={goToStep2}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noiseSequence, isAudioPlayable]
  );

  const switchTrainingToPlayTutorial = useCallback(() => {
    if (!isTraining) return;
    dispatch(
      setModeGame({
        mode: modeGame.mode,
        type: TypeGameEnum.PLAYING_TUTORIAL,
      })
    );
  }, [dispatch, isTraining, modeGame.mode]);

  const handleSquareClick = (index: 0 | 1) => {
    const isCorrect = onSquareCouplesResponse(index);
    if (isTutorial) setErrorSquare(isCorrect ? null : index);
    switchTrainingToPlayTutorial();
  };

  const renderSquare = (index: 0 | 1, size: number) => (
    <div
      style={{ width: size }}
      className={`snake-square-step-1 ${
        interactionWarning || isErrorWarning ? "warning-border" : ""
      } ${errorSquare === index ? "error-square" : ""}`}
      onClick={() => handleSquareClick(index)}
    >
      {errorSquare === index && isErrorWarning && (
        <div className="snake-warning-card">
          <SimpleCard text={["Fai attenzione"]} bottomTip />
        </div>
      )}
    </div>
  );

  return (
    <div className="snake-step-1 fade-in-image">
      <div className="first-square">{renderSquare(0, squareSize[0])}</div>

      <div className={`snake-warning-card`}>
        {interactionWarning && <SimpleCard text={["Fai una scelta"]} bottomTip />}
      </div>

      <div className="second-square">{renderSquare(1, squareSize[1])}</div>
      {renderSpeech}
    </div>
  );
};

export default StepOne;
