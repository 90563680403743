import alone from "assets/img/common/elements/alone-right.png";
import stantingSlothsTwo from "assets/img/sloth/elements/brady-sonny-spinoff-1.svg";
import rememberFelix from "assets/img/common/Detective/remember-zoom.svg";
import standingSlothsOne from "assets/img/sloth/elements/sonny-brady-start-spinoff-1.svg";
import layingSlothsTwo from "assets/img/sloth/elements/sonny-lula-2-start-spinoff-2.svg";
import layingSlothsOne from "assets/img/sloth/elements/sonny-lula-start-spinoff-2.svg";
import bradyHello from "assets/img/sloth/elements/brady-hello.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, IMultiOptionScene } from "models/ChapterSceneFC";
import { FC, useCallback, useMemo } from "react";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./SlothSpinoffSceneOne.scss";
import { useGamePath } from "hook/useGamePath";
import { EnumGame } from "models/EnumPath";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

const SlothSpinoffSceneOneData: IMultiOptionScene = {
  spinoffFirst: [
    {
      title: "Brady",
      text: [
        "Rieccoti qui! Oltre me, anche altri bradipi hanno bisogno del tuo aiuto per riavere i loro alberi.",
      ],
      audio: {
        sloths: "sloth/brady-spinoff-first-1",
      },
      images: {
        sloths: standingSlothsOne,
      },
    },
    {
      title: "Sonny",
      text: [
        "Brady mi ha detto che hai fatto un ottimo lavoro con il suo albero. Puoi far crescere velocemente anche il mio?",
      ],
      audio: {
        sloths: "sloth/sonny-spinoff-first-1",
      },
      images: {
        sloths: stantingSlothsTwo,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Ricordi come funziona? Ogni volta che giocherai ci aiuterai a ripopolare la foresta.",
      ],
      audio: {
        felix: "sloth/detective-felix-spinoff-1",
      },
      images: {
        felix: rememberFelix,
      },
    },
  ],
  spinoffSecond: [
    {
      title: "Lula",
      text: [
        "Ti stavamo aspettando. Rivorrei tanto indietro il mio alberello! Mi aiuti a farlo crescere come hai fatto con gli altri?",
      ],
      audio: {
        sloths: "sloth/lula-spinoff-second-1",
      },
      images: {
        sloths: layingSlothsOne,
      },
    },
    {
      title: "Sonny",
      text: [
        "Lula ha tanto sonno ma ha bisogno del ramo di un albero per mettersi comoda. È abituata così, come tutti noi.",
      ],
      audio: {
        sloths: "sloth/sonny-spinoff-second-1",
      },
      images: {
        sloths: layingSlothsTwo,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Ricordi come funziona? Ogni volta che giocheremo un nuovo albero crescera più velocemente.",
      ],
      audio: {
        felix: "sloth/detective-felix-spinoff-default-1",
      },
      images: {
        felix: rememberFelix,
      },
    },
  ],
  spinoffDefault: [
    {
      title: "Brady",
      text: [
        "Rieccoti qui! Ci sono altri alberi da far crescere per ripopolare la foresta. Ci aiuti?",
      ],
      audio: {
        sloths: "sloth/brady-spinoff-default-1",
      },
      images: {
        sloths: bradyHello,
      },
    },
    {
      //TODO: Tutto il flusso delle scene iniziali degli spinoff ha 3 pagine,
      // ad eccezione dello spinoffDefault, questo ci costringe ad avere una pagina
      // vuota tra la prima e la terza. Non abbiamo modo per non renderizzarla
      title: "",
      text: [],
      images: {},
    },
    {
      title: "Detective Felix",
      text: [
        "Ricordi come funziona? Ogni volta che giocherai ci aiuterai a ripopolare la foresta.",
      ],
      audio: {
        felix: "sloth/detective-felix-spinoff-1",
      },
      images: {
        felix: rememberFelix,
      },
    },
  ],
};

const SlothSpinoffSceneOne: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { currentSceneId } = useGamePath(EnumGame.SLOTH_GAME);
  const dataKey =
    (currentSceneId?.split("-")[0] as keyof IMultiOptionScene) || "spinoffDefault";

  const contents = SlothSpinoffSceneOneData[dataKey];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const getAudio = useCallback(
    (page: number) => {
      if (page === 3) {
        return `${contents[2].audio?.felix}`;
      } else if (!contents[page - 1]?.audio?.sloths) {
        return "";
      } else {
        return `${contents[page - 1].audio?.sloths}`;
      }
    },
    [contents]
  );

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[getAudio(page)]}
        start={true}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [getAudio, page, setIsFinished]
  );

  return (
    <div
      className={`container-spinoff-one fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div
        className={`content ${
          currentSceneId === "spinoffFirst-one" ? "bg-first" : "bg-second"
        }`}
      >
        {page === 3 && <div className="bg-overlay" />}
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { sloths, felix },
          } = contents[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`spinoff-page-${page}`}
                className={`page-content ${
                  currentSceneId === "spinoffFirst-one" ? "first" : "second"
                } 
                ${getTransitionClass([page, pageDiv + 1])}`}
              >
                {[1, 2].includes(pageDiv + 1) && sloths && (
                  <img
                    src={sloths}
                    className={`sloths-figure sloths-${pageDiv + 1} sloth-${dataKey}`}
                    alt="Our Sloths Friends happy to see us"
                  />
                )}
                {pageDiv + 1 === 3 && (
                  <>
                    <img src={alone} className="alone" alt="an alone behind Felix" />
                    <img src={felix} className="felix-figure" alt="felix need our attention" />
                  </>
                )}
                {(dataKey !== "spinoffDefault" || [1, 3].includes(pageDiv + 1)) && (
                  <div className={`balloon-container balloon-${pageDiv + 1}`}>
                    <SimpleCard title={title} text={text} />
                  </div>
                )}
                {renderSpeech}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default SlothSpinoffSceneOne;
