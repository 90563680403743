import { FC, useMemo } from "react";
import "./SnakeFwdIntro.scss";
import { ChapterSceneFC, IMultiOptionScene, SceneDataT } from "models/ChapterSceneFC";
import getClasses from "utils/getClasses";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import talkingFelix from "assets/img/common/Detective/detective-talk-zoom.webp";
import doubtFelix from "assets/img/common/Detective/detective-doubt.svg";
import rememberFelix from "assets/img/common/Detective/detective-remember.webp";
import snakesPinkAndGreen from "assets/img/snake/snakes-1.webp";
import snakesOrangeAndGreen from "assets/img/snake/snakes-2.webp";
import snakeYellowAndGreen from "assets/img/snake/snakes-3.webp";
import sibilloFirst from "assets/img/snake/Sibillo-fwd-1.webp";
import sibilloSecond from "assets/img/snake/Sibillo-fwd-2.webp";
import grassBottom from "assets/img/snake/grass_1.webp";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { CurrentUser } from "models/CurrentUser";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { useGamePath } from "hook/useGamePath";
import { EnumGame } from "models/EnumPath";

/**
 * SnakeFwdIntroSceneOne Component
 *
 * This component represents a first chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} page - Indicates number of the scene inside component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const snakeFwdIntroSceneOneData: IMultiOptionScene = {
  forward: [
    {
      title: "Sibillo",
      text: [
        "Chi vi ha ridotto così? E dove sono finiti tutti gli altri? Detective Felix, per favore, aiutami a liberare i miei amici!",
      ],
      audio: {
        sibillo: "snake/sibillo-01",
      },
      images: {
        snakes: sibilloFirst,
      },
    },
    {
      title: "Detective Felix",
      text: ["I casi stanno aumentando, ma sento che siamo sempre più vicini ai colpevoli. "],
      audio: {
        detective: "snake/detective-felix-01",
      },
      images: {
        felix: doubtFelix,
        snakes: sibilloSecond,
      },
    },
    {
      title: "Detective Felix",
      text: ["Ogni volta che giocherai potremo liberare una coppia di serpenti. Sei pronto?"],
      audio: {
        felix_F: "snake/detective-felix-02_F",
        felix_M: "snake/detective-felix-02_M",
      },
      images: {
        felix: talkingFelix,
      },
    },
  ],

  spinoffFirst: [
    {
      title: "Sibillo",
      text: [
        "Che bello rivederti! Restano ancora alcune coppie di serpenti da liberare, puoi aiutarci?",
      ],
      audio: {
        sibillo: "snake/sibillo-spinoffFirst-01",
      },
      images: {
        snakes: snakesPinkAndGreen,
        greenGrass: grassBottom,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Ricordi come funziona? Ogni volta che giocheremo potremo liberare una coppia di serpenti.",
      ],
      audio: {
        detective: "snake/detective-felix-02",
      },
      images: {
        snakes: snakesPinkAndGreen,
        greenGrass: grassBottom,
        felix: rememberFelix,
      },
    },
  ],

  spinoffSecond: [
    {
      title: "Sibillo",
      text: ["Che bello rivederti! \nZara e Filo sono ancora aggrovigliati.Puoi aiutarli?"],
      audio: {
        sibillo: "snake/sibillo-spinoffSecond-01",
      },
      images: {
        snakes: snakesOrangeAndGreen,
        greenGrass: grassBottom,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Ricordi come funziona? Ogni volta che giocheremo potremo liberare una coppia di serpenti.",
      ],
      audio: {
        detective: "snake/detective-felix-02",
      },
      images: {
        snakes: snakesOrangeAndGreen,
        greenGrass: grassBottom,
        felix: rememberFelix,
      },
    },
  ],

  spinoffDefault: [
    {
      title: "Sibillo",
      text: [
        "Che bello rivederti! Restano ancora alcune coppie di serpenti da liberare, puoi aiutarci?",
      ],
      audio: {
        sibillo: "snake/sibillo-spinoffDefault-01",
      },
      images: {
        snakes: snakeYellowAndGreen,
        greenGrass: grassBottom,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Ricordi come funziona? Ogni volta che giocheremo potremo liberare una coppia di serpenti.",
      ],
      audio: {
        detective: "snake/detective-felix-02",
      },
      images: {
        snakes: snakeYellowAndGreen,
        greenGrass: grassBottom,
        felix: rememberFelix,
      },
    },
  ],
};

type dataKeyT = keyof typeof snakeFwdIntroSceneOneData;

export const SnakeFwdIntroSceneOne: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { currentSceneId } = useGamePath(EnumGame.SNAKE_GAME);
  const dataKey = currentSceneId?.split("-")[0] as dataKeyT;
  const contents: SceneDataT = snakeFwdIntroSceneOneData[dataKey];

  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });
  const { currentUser } = useGetCurrentUser();

  const getAudio = (
    contents: SceneDataT,
    page: number,
    currentUserData: CurrentUser | undefined
  ) => {
    const audio = contents[page - 1].audio;

    if (audio?.sibillo) {
      return `${audio?.sibillo}`;
    } else if (audio?.detective) {
      return audio.detective;
    } else if (audio?.felix_M && currentUserData?.gender === "MALE") {
      return audio.felix_M;
    } else if (audio?.felix_F && currentUserData?.gender === "FEMALE") {
      return audio.felix_F;
    } else {
      return "";
    }
  };

  const getBackgroundImage = (dataKey: dataKeyT) => {
    if (dataKey === "forward" && page === 3) {
      return "snake-forward-container-2";
    } else if (dataKey === "spinoffFirst") {
      return "snake-spinoffFirst-container";
    } else if (dataKey === "spinoffSecond" || dataKey === "spinoffDefault") {
      return "snake-spinoff-container";
    } else {
      return "snake-forward-container";
    }
  };

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[getAudio(contents, page, currentUser)]}
        start={Boolean(page)}
        startDelay={page === 1 ? 1000 : 0}
        endDelay={1000}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [contents, currentUser, page, setIsFinished]
  );

  return (
    <div
      className={`${getBackgroundImage(dataKey)} fade-in-image ${getClasses({
        "fade-out-image": out && page === 3,
      })}`}
    >
      <div className="content">
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { felix, snakes, greenGrass },
          } = contents[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div key={`scene-page-${pageDiv + 1}`} className="page-container">
                {snakes && (
                  <img
                    className={`snakes${
                      dataKey === "forward" && page === 2 ? "-2 fade-in-image" : ""
                    } ${page === 1 ? "fade-in-image" : ""}`}
                    src={snakes}
                    alt="tangled snakes"
                  />
                )}
                <div className={`balloon-container balloon-${pageDiv + 1} fade-in-image`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
                {page !== 1 && (
                  <img
                    className={` felix-figure ${page === 2 ? "fade-in-image" : ""}`}
                    src={felix}
                    alt="felix"
                  />
                )}
                {greenGrass && <img className="grass-bottom" src={greenGrass} alt="grass" />}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
