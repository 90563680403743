import { FC } from "react";
import { useEffectAudio } from "hook/useEffectAudio";
import { RepeatAudioBtnFC } from "models/RepeatAudioBtnFC";
import "./RepeatAudioBtn.scss";

export const RepeatAudioBtn: FC<Partial<RepeatAudioBtnFC>> = ({
  action,
  isDisabled = false,
  isHidden = false,
}) => {
  const { activePlayButton, volumeMusic } = useEffectAudio();

  const handleClick = () => {
    if (action) {
      volumeMusic && activePlayButton();
      action();
    }
  };

  return (
    <>
      {!isHidden && (
        <button className="round repeat" onClick={handleClick} disabled={isDisabled}>
          <i className="icon icon-replay" />
        </button>
      )}
    </>
  );
};
