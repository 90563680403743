import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useEffect, useMemo } from "react";
import React from "react";
import detectiveFelix from "assets/img/finalMessage/Felix-happy.webp";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import "./FinalMessageFwdTwo.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";

const content: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
  {
    title: "Detective Felix",
    text: [
      "Grazie a te la foresta tropicale di Eufauna è tornata un posto sicuro. Ma a causa di queste persone gli animali selvatici sono sempre in pericolo. Cosa puoi fare per aiutarli?",
    ],
    images: { felix: detectiveFelix },
    //TODO: audio provvisorio
    audio: { felix: "message/felix-message-1" },
  },
];

export const FinalMessageFwdTwo: FC<Partial<ChapterSceneFC>> = React.memo(
  ({ page = 1, action }) => {
    const contents = content;
    const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

    useEffect(() => {
      page === 2 && setIsFinished(false);
    }, [page, setIsFinished]);

    const renderAudio = useMemo(
      () => (
        <SpeechAudio
          notVisible
          start={Boolean(page)}
          audioFiles={[...(page === 2 ? [contents[1].audio!.felix] : [])]}
          setFinished={() => setIsFinished(true)}
        />
      ),
      [contents, page, setIsFinished]
    );

    return (
      <div className="final-message-container fade-in-image">
        {renderAudio}
        {page === 2 && (
          <>
            <div className="container-gradient fade-in-image" />
            <img className="img-detective" src={contents[1].images.felix} alt="felix" />
            <SimpleCard title={contents[0].title} text={[contents[1].text[0]]} />
          </>
        )}
      </div>
    );
  }
);
