import { useEffectAudio } from "hook/useEffectAudio";
import { FC } from "react";
import { Link } from "react-router-dom";
import { RoundButtonFC } from "../../models/RoundButtonFC";
import "./RoundButton.scss";

/**
 * A round button component.
 *
 * @param {RoundButtonFC} props - The properties for the RoundButton component.
 * @param {string} props.type - The type of the button ('music' or 'voice').
 * @param {Function} props.action - The function to be called when the button is clicked.
 * @param {boolean} props.isMarginRight - A flag to determine if there should be a right margin.
 * @param {boolean} props.isMute - A flag to determine if the button represents a muted state.
 * @param {string} props.linkButton - The route to navigate to for "back" or "close" buttons.
 * @param {string} props.blockNavigation - Navigation block
 * @returns {JSX.Element} The JSX element representing the round button.
 */

export const RoundButton: FC<Partial<RoundButtonFC>> = ({
  type,
  action,
  isMarginRight,
  isDisabled = false,
  isMute,
  linkButton,
  blockNavigation = false,
}) => {
  const { activePlayButton, volumeMusic } = useEffectAudio();

  const renderIcon = () => {
    const iconName =
      (type === "music" || type === "voice") && isMute ? `no-${type}`
      : type === "tutorial-replay" ? "demo-replay"
      : type === "replay" ? "play"
      : type;
    return <i className={`icon icon-${iconName}`} />;
  };

  const handleClick = () => {
    if (action) {
      volumeMusic && activePlayButton();
      !blockNavigation && action();
    }
  };

  if (linkButton) {
    return (
      <Link
        data-cy="link-button"
        to={linkButton}
        onClick={handleClick}
        className={`no-decoration ${isMarginRight ? "margin-right" : ""} round ${type}`}
      >
        {renderIcon()}
      </Link>
    );
  } else {
    return (
      <button
        data-cy="rounded-button"
        className={`${isMarginRight ? "margin-right" : ""} round ${type}`}
        onClick={handleClick}
        disabled={isDisabled}
      >
        {renderIcon()}
      </button>
    );
  }
};
