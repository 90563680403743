import { EnumGame, EnumPath } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";

export interface GameCardMenu {
    title: string;
    imagePath: string;
    componentToRender: SceneEnum;
    spinOff: string;
    pathGame: string;
    initialProgress: number;
    gameName: EnumGame;
}

export const gameCardMenu: GameCardMenu[] = [
    {
        title: "La scomparsa dei pappagalli",
        imagePath: "missing-parrots",
        componentToRender: SceneEnum.FORWARD_STARTED,
        pathGame: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.SCENE}/${SceneEnum.FORWARD_STARTED}`,
        spinOff: SceneEnum.SPINOFF_FIRST_ONE,
        initialProgress: 0,
        gameName: EnumGame.PARROT_GAME,
    },
    {
        title: "La ricerca del piccolo tucano",
        imagePath: "little-toucan",
        componentToRender: SceneEnum.FORWARD_STARTED,
        pathGame: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.SCENE}/${SceneEnum.FORWARD_STARTED}`,
        spinOff: "",
        initialProgress: 0,
        gameName: EnumGame.TOUCAN_GAME,
    },
    {
        title: "Il pisolino interrotto del bradipo",
        imagePath: "sloth-nap",
        componentToRender: SceneEnum.FORWARD_STARTED,
        pathGame: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.SCENE}/${SceneEnum.FORWARD_STARTED}`,
        spinOff: "",
        initialProgress: 0,
        gameName: EnumGame.SLOTH_GAME,
    },
    {
        title: "Il caso dei serpenti aggrovigliati",
        imagePath: "tangled-snake",
        componentToRender: SceneEnum.FORWARD_STARTED,
        pathGame: `/${EnumPath.GAMES}/${EnumPath.SNAKE_GAME}/${EnumPath.SCENE}/${SceneEnum.FORWARD_STARTED}`,
        spinOff: "",
        initialProgress: 0,
        gameName: EnumGame.SNAKE_GAME,
    },
];
