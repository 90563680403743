import { getSnakeGameImg } from "assets/img/snake/game";
import { EnumSnakeGameSquare } from "data/snakeGameStimuli";
import "./StepTwo.scss";
import { useEffectAudio } from "hook/useEffectAudio";
import { useEffect, useState } from "react";

interface Props {
  noiseCards: Array<string>;
  addNoiseToNoiseResponse: (s: string) => void;
  interactionWarningTimeOut: () => void;
  isTransitioning: boolean;
  interactionWarning: boolean;
  isTutorial: boolean;
  noiseSequence: Array<string>;
  onBalloonTextChange: (text: string[]) => void;
  setNoiseResponse: (response: Array<string>) => void;
}

const StepTwo = ({
  noiseCards,
  addNoiseToNoiseResponse,
  interactionWarningTimeOut,
  isTransitioning,
  interactionWarning,
  isTutorial,
  noiseSequence,
  onBalloonTextChange,
  setNoiseResponse,
}: Props) => {
  const { activePlayButton } = useEffectAudio();
  const [clickedCards, setClickedCards] = useState<string[]>([]);
  const [hasInitiallyRendered, setHasInitiallyRendered] = useState(false);
  const [lastClickedCard, setLastClickedCard] = useState<string | null>(null);

  useEffect(() => {
    setHasInitiallyRendered(true);
  }, []);

  const renderedCards = noiseCards.map((noise, i) => {
    const imgSrc = getSnakeGameImg(noise as keyof typeof EnumSnakeGameSquare);
    const hasClicked = clickedCards.includes(noise);
    const isLastClicked = lastClickedCard === noise;

    const getInteractionClass = () => {
      const classes = [];

      if (hasClicked || isLastClicked) {
        classes.push("clicked");
      }

      if (interactionWarning && !hasClicked) {
        classes.push("no-interaction");
      }

      if (!hasInitiallyRendered && !interactionWarning) {
        classes.push("fade-in-step-2");
      }

      return classes.join(" ");
    };

    const handleClick = () => {
      if (!isTransitioning && isTutorial) {
        // Ci prendiamo la carta che ci aspettiamo in base alla sequenza di suoni
        const expectedCard = noiseSequence[clickedCards.length];
        setLastClickedCard(noise);

        // Controlliamo se la carta cliccata è quella che ci aspettiamo
        if (noise !== expectedCard) {
          onBalloonTextChange(["Riprova.", "Puoi farcela!"]);
          setClickedCards([]);
          setLastClickedCard(null);
          setNoiseResponse([]);
          setTimeout(() => {
            onBalloonTextChange([
              "Adesso in ordine tocca le immagini che corrispondo ai suoni che hai ascoltato",
            ]);
          }, 3000);
        } else {
          onBalloonTextChange([
            "Adesso in ordine tocca le immagini che corrispondo ai suoni che hai ascoltato",
          ]);
          addNoiseToNoiseResponse(noise);
          activePlayButton();
          setClickedCards((prev) => [...prev, noise]);
        }
      } else if (!isTransitioning) {
        // Play normal game
        addNoiseToNoiseResponse(noise);
        setLastClickedCard(noise);
        activePlayButton();
        setClickedCards((prev) => [...prev, noise]);
      }
      interactionWarningTimeOut();
    };

    return (
      <div
        key={noise + i}
        className={`snake-square-step-2 flip-box-front ${getInteractionClass()}`}
        onClick={handleClick}
      >
        <div className="wrapper-image">
          <img className="snake-image-step-2" alt={noise} src={imgSrc} />
        </div>
      </div>
    );
  });

  return <div className="snake-step-2 ">{renderedCards}</div>;
};

export default StepTwo;
