import { DemoSnakeGame } from "components/DemoGame/DemoSnakeGame/DemoSnakeGame";
import { Modal } from "components/Modal/Modal";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { useMemo } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  type: "tutorial" | "playGame";
}

const IntroModal = ({ isOpen, onClose, type }: Props) => {
  const { currentUser } = useGetCurrentUser();

  const getContent = () => {
    switch (type) {
      case "tutorial":
        return {
          text: "Prima di procedere, facciamo una prova",
          audio: "general/detective-felix-before-do-test",
        };
      case "playGame":
        return {
          text: `Ok, sei pront${
            currentUser && currentUser.gender === "MALE" ? "o" : "a"
          }. Giochiamo!`,
          audio: `general/detective-felix-ok-u-ready-${
            currentUser && currentUser.gender === "MALE" ? "M" : "F"
          }`,
        };

      default:
        return {
          text: "Prima di procedere, facciamo una prova",
          audio: "general/detective-felix-before-do-test",
        };
    }
  };

  const content = getContent();

  const renderAudio = useMemo(
    () => <SpeechAudio notVisible audioFiles={[`${content.audio}`]} start={isOpen} />,
    [content.audio, isOpen]
  );

  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <div className={`${isOpen ? "fade-in-image" : "fade-out-image"}`}>
        <DemoSnakeGame
          data-cy="demo-modal"
          type="demo"
          action={onClose}
          startTutorial={() => {}}
          title={content.text}
        />
        {renderAudio}
      </div>
    </Modal>
  );
};

export default IntroModal;
